import React, { useEffect, useState } from 'react';
import done from '../../../images/done.gif';
import './ProductPurchaseConfirm.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoMdShare } from 'react-icons/io';
import { GiSaveArrow } from 'react-icons/gi';
import SearchBar from '../../EcommerceComponent/searchBar';
import html2pdf from 'html2pdf.js';
import useFectch from '../../../utility/getFunction';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { vitelWirelessAxios } from '../../../utility/axios';
import logo from '../../../images/newLogo.png';

const ProductPurchaseConfirm = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const { orderId } = useParams();
  const [selectDeliveryFee, setSelectDeliveryFee] = useState('');

  const { returnOderCheck, amount, MSISDN, subscriberInfo, paystackPaymentReference } = location.state || {};

  // useEffect(() => {
  //   document.title = amount
  //     ? `Vitel Wireless | purchase confirmation`
  //     : "purchase confirmation";
  // }, [amount]);

  // console.log('order->>ID', orderId);

  // console.log('orderSerialNumbers', orderSerialNumbers);

  const handleSubmitAddress = async data => {
    // console.log("main>>>>>", AddressData,)
    await axios
      .get('/subscribers/getOrdersReceipt?sourceType=web')
      .then(res => {
        // console.log('sucessData', res.data);
      })
      .catch(err => {
        console.log('err', err);
        // setPendingAdress(false);
      });
  };

  console.log('returnOderCheck>>>>', returnOderCheck);

  const handleBackToHome = () => {
    navigation('/');
  };

  const handleShareReceipt = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Airtime Top-up Receipt',
          // text: `Your airtime top-up of ${amount} to ${MSISDN} was successful. Reference number: ${paystackPaymentReference}.`,
          url: window.location.href, // You can add a relevant URL here
        })
        .then(() => console.log('Receipt shared successfully'))
        .catch(error => console.error('Error sharing receipt:', error));
    } else {
      alert('Sharing is not supported in this browser.');
    }
  };

  const generatePDF = () => {
    const options = {
      margin: [0.5, 1, 0.5, 1],
      filename: `VW_${orderId}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    const content = document.getElementById('pdfContent');

    html2pdf().set(options).from(content).save();
  };

  const generateAndSharePDF = async () => {
    const element = document.getElementById('pdfContent');
    const options = {
      margin: [0.5, 1, 0.5, 1],
      filename: `VW_${orderId}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    try {
      // Generate PDF as Blob
      const pdfBlob = await html2pdf().from(element).set(options).outputPdf('blob');

      // Convert Blob to File
      const pdfFile = new File([pdfBlob], `VW_${orderId}.pdf`, {
        type: 'application/pdf',
      });

      // Check if Web Share API is supported
      if (navigation.canShare && navigation.canShare({ files: [pdfFile] })) {
        // Use Web Share API
        await navigation.share({
          title: `VW_${orderId}.pdf`,
          text: 'Check out this PDF file!',
          files: [pdfFile], // Pass the file for sharing
        });

        // console.log('Shared successfully');
      } else {
        // Fallback for unsupported browsers
        alert('Sharing not supported on this browser; the file will be downloaded automatically');
        // // console.log('Sharing not supported. Downloading the PDF instead.');
        // // const pdfURL = URL.createObjectURL(pdfBlob);
        // // const link = document.createElement('a');
        // // link.href = pdfURL;
        // // link.download = `VW_${orderId}.pdf`;
        // // link.click(); // Trigger download
        // URL.revokeObjectURL(pdfURL); // Cleanup the URL
      }
    } catch (error) {
      console.error('Error generating or sharing the PDF:', error);
    }
  };

  const { productItems } = useSelector(state => state.cartProduct);

  // console.log('productItems >>>', productItems);

  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  const subscribersData = JSON.parse(localStorage.getItem('subscriberInformation'));

  useEffect(() => {
    setIsPending(true);
    vitelWirelessAxios
      .get(`/subscribers/getOrdersReceipt/${orderId}?sourceType=web`)
      .then(response => {
        console.log('response ===>>>', response);
        if (!response.statusText === 'OK') {
          throw Error('Could not fetch data');
        } else {
          setIsPending(false);
          setIsPending(false);
          console.log('response.data new', response.data);
          setData(response.data);

          setError(null);
        }
      })
      .catch(error => {
        setError(error.message);
        setIsPending(true);
        console.log(error);
      });
  }, [orderId]);

  console.log('data>>>>>>>>>1000>>', data);

  const totalAmount = data.totalAmount;

  const calculateVAT = (pricePerItem, quantity, addVat) => {
    if (addVat === 'Yes') {
      return (pricePerItem * quantity * 7.5) / 100;
    }
    return 0;
  };

  const vatCalculateGrandTotal = () => {
    return data.products?.reduce((total, item) => {
      return total + calculateVAT(item.pricePerItem, item.quantity, item.addVat);
    }, 0);
  };

  const calculateItemTotalPrice = (pricePerItem, quantity) => {
    return pricePerItem * quantity;
  };

  const calculateItemTotal = (pricePerItem, quantity, addVat) => {
    return calculateItemTotalPrice(pricePerItem, quantity) + calculateVAT(pricePerItem, quantity, addVat);
  };

  const calculateGrandTotal = () => {
    return data.products?.reduce((grandTotal, item) => {
      return grandTotal + calculateItemTotal(item.pricePerItem, item.quantity, item.addVat);
    }, 0);
  };
  const deliveryFees = 2000;

  const calculateAmountPaid = calculateGrandTotal();

  useEffect(() => {
    setSelectDeliveryFee(data.typeOfService === 'delivery' ? deliveryFees : 0);
  }, [deliveryFees, calculateAmountPaid]);
  //  const calculateAmountPaid =

  // console.log("calculateAmountPaid>>>>>>>", calculateAmountPaid + selectDeliveryFee)

  // const mergedProducts = data?.products?.map(product => {
  //   // Step 2: Find matching serial numbers based on productId
  //   const matchingSerialNumbers = data?.orderSerialNumbers?.filter(serial => serial.productId === product.orderProductId);

  //   // Step 3: Attach the matching serial numbers to the product
  //   return {
  //     ...product,
  //     serialNumbers: matchingSerialNumbers
  //   };
  // });

  // console.log("mergedProducts", mergedProducts)

  return (
    <div className="parentAirtimetopsuccess">
      <SearchBar />
      <div className="book_form_room_list">
        <div className="book_hotel__details">
          <div className="book_hotel_img">
            <img src={logo} alt="" />
          </div>
        </div>
        <div id="pdfContent">
          <div className="book_hotel__details2">
            {/* <div className="receiptLogo">
              <img src={logo} alt="" />
            </div> */}
            <h4>Thank you for shopping with vitel wireless</h4>
          </div>

          <div className="book_view_more">
            <span className="spanDis">
              <span>MSISDN: </span>
              <span>{data.customerData?.phoneNumber}</span>{' '}
            </span>
          </div>

          <div className="book_view_more_with_tax ">
            <span className="spanDis">
              <span>Name on MSISDN</span>
              <span>
                {data.customerData?.firstName} {data.customerData?.lastName}
              </span>{' '}
            </span>
          </div>

          {/* <div className='book_view_more_with_tax'>
          <span className='spanDis'>
            <span>Recharge Amount</span>
            <span>{amount}</span>{" "}
          </span>
        </div> */}
          <div className="watermark"></div>
          <div className="book_view_more_with_tax subTotal">
            <span className="spanDis">
              <span>Order reference number</span>
              <span>{data?.orderId}</span>{' '}
            </span>
          </div>
          <div className="orderbreakDownDiv subTotal">
            <table className="table">
              <thead className="table-dark">
                <tr>
                  <th scope="col">S/N</th>
                  <th scope="col">Product Name</th>
                  {/* <th scope="col">Product S/N</th> */}
                  <th scope="col">Unit price</th>
                  <th scope="col">Value Code</th>
                  <th scope="col">Quantity</th>

                  <th scope="col">VAT(7.5%)</th>
                  <th scope="col">Total AMount</th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {data.products &&
                  data.products.map((data, i) => (
                    <tr>
                      <th scope="row">{i + 1} </th>
                      <td> {data.comboName} </td>
                      {/* <td>N/A</td> */}
                      <td> ₦ {data.pricePerItem}</td>
                      <td>N/A</td>
                      <td>{data.quantity} </td>
                      <td> ₦ {calculateVAT(data.pricePerItem, data.quantity, data.addVat)} </td>
                      <td> ₦ {calculateItemTotal(data.pricePerItem, data.quantity, data.addVat)}</td>
                    </tr>
                  ))}

                <tr style={{ borderTop: '2px solid black', borderBottom: 'none' }}>
                  <th style={{ border: 'none' }}>Total</th>
                  {/* <td></td> */}
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ border: 'none', fontWeight: 'bold' }}> ₦{vatCalculateGrandTotal()}</td>
                  <td style={{ border: 'none', fontWeight: 'bold' }}> ₦{calculateGrandTotal()} </td>
                </tr>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }} colSpan="6">
                    Delivery fee
                  </th>
                  <td style={{ border: 'none', fontWeight: 'bold' }}>
                    {' '}
                    ₦{data.typeOfService === 'delivery' ? 2000 : 0.0}{' '}
                  </td>
                </tr>
                <tr style={{ border: 'none' }}>
                  <th style={{ border: 'none' }} colSpan="6">
                    Delivery type
                  </th>
                  <td style={{ border: 'none', fontWeight: 'bold' }}> {data.typeOfService} </td>
                </tr>

                <tr style={{ borderTop: '2px solid black' }}>
                  <th colSpan="6">Amount Paid</th>
                  <td style={{ fontWeight: 'bolder' }}> ₦{calculateAmountPaid + selectDeliveryFee}</td>
                </tr>
                {/* <div style={{ background: '#000', width: '100%' }}>
                  <span>All purchase products serial Number </span>
                  <ul>{returnOderCheck && returnOderCheck?.map(serialNumber => <li>{serialNumber}</li>)}</ul>
                </div> */}
              </tbody>
            </table>
            <div style={{ padding: '15px' }}>
              <h5>purchased product serial numbers</h5>
              <ol>{data && data?.orderSerialNumbers?.map(serialNumber => <li>{serialNumber.serialNumber}</li>)}</ol>
            </div>
          </div>

          <div className="book_view_more_with_tax subTotal deliveryInfo">
            <span>
              {data.typeOfService === 'pickup' ? <h5>Your pickUp center</h5> : <h5>Your Delivery Address</h5>}
            </span>
            <p className="deliveryText">
              {data.typeOfService === 'pickup'
                ? `${data?.customerData?.area} ${data?.customerData?.lgaName}, ${data?.customerData?.stateName}.`
                : `${data?.customerData?.deliveryAddress}, ${data?.customerData?.lgaName}, ${data?.customerData?.stateName}`}
            </p>

            <span>
              <h5>Your Purchase is Complete!</h5>
              <p className="deliveryText">
                Thank you for your purchase! Your order has been placed successfully. You will receive a confirmation
                email soon, and your items will be shipped within the next 2-3 business days. We hope you enjoy your
                purchase!
              </p>
            </span>
          </div>
        </div>
        <div className="backToHome">
          <div className="saveIconDiv" onClick={generatePDF}>
            <span className="shareIconspan">
              <GiSaveArrow className="saveIcon" />
            </span>
            <span>Download Receipt</span>
          </div>
          <div className="shareIconDiv saveIconDiv2" onClick={generateAndSharePDF}>
            <span className="shareIconspan">
              <IoMdShare className="saveIcon" />
            </span>
            <span>Share Receipt</span>
          </div>

          <button className="getBackBtn" onClick={handleBackToHome}>
            Back To Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductPurchaseConfirm;
