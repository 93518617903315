import React, { useState, useEffect } from 'react';
import { orderLocationId, orderMenuId, vitelWirelessAxios } from '../../utility/axios';
import './IoTDevices.css';
import noProduct from '../../images/newNoProduct.jpg';
import bracelet from '../../images/smartbracelet.png';
import watch from '../../images/wear/watch.jpg';
import camera from '../../images/wear/camera.jpg';
import vrGames from '../../images/wear/vrgames.jpg';
import { Link, useNavigate } from 'react-router-dom';

const IoTDevices = () => {
  const [VitelIoTProduct, setVitelIoTProduct] = useState([]);
  const [VitelIoTProductCategory, setVitelIoTProductCategory] = useState([]);
  const [checkEmptyArr, setCheckEmptyArr] = useState();
  const [categoryNameArray, setCategoryNameArray] = useState([]);
  const [productId, setProductId] = useState('');
  const navigate = useNavigate();

  // const handleCategoryChange = async e => {
  //   const selectedCategory = e.target.value;
  //   const productDetails = await vitelWirelessAxios.get(`/generals/getAllPublicImages`);

  // }
  const handleCategoryChange = async e => {
    const selectedCategory = e.target.value;
    const productDetails = await vitelWirelessAxios.get(`/products/menus/${orderMenuId}?locationId=${orderLocationId}`);

    const DataIoTProductDetails = productDetails?.data;

    

    const subCategoryImages =
      DataIoTProductDetails.products &&
      DataIoTProductDetails.products
        .filter(product => product.productDetailImages && product.productDetailImages.length > 0)
        .flatMap(product => product.productDetailImages);

        console.log('DataIoTProductDetails>>>>>>>>', selectedCategory);

   const uniqueCategoriesMap = new Map();

  

      // Iterate over the data and add the first product of each category to the map
      subCategoryImages && subCategoryImages.forEach(item => {
      if (!uniqueCategoriesMap.has(item.categoryId)) {
      uniqueCategoriesMap.set(item.categoryId, item);
      }
      });

  // Convert the map values to an array
      const uniqueCategories = Array.from(uniqueCategoriesMap.values());

      const filterCategory = uniqueCategories && uniqueCategories.filter(product =>
        product.categoryName != "Premium/Value Added Services" && product.categoryName != "Credit Vouchers" && product.categoryName != "data"
      )
    

      setCategoryNameArray(filterCategory)
   
        

    if (selectedCategory === 'all') {
      const filteredProductServOnly = subCategoryImages.filter(
        service =>
          service.categoryName == 'IoT Devices' ||
          service.categoryName === 'Wearables' ||
          service.categoryName === 'Mobile Phones'
      );
      const subcategoryArray = filteredProductServOnly.flatMap(item => item);

      setVitelIoTProduct(subcategoryArray );
      
     } else {
      
      const filteredProducts = uniqueCategories && uniqueCategories.filter(product => 
        product.categoryId === selectedCategory
      ); 


         setVitelIoTProduct(filteredProducts);
    }
  };

  const encodedProductName = devices => {
    return encodeURIComponent(devices.productName);
  };




  const handleNavigateDetails = devices => {
    console.log('devices', devices);
    // const encodedProductName = encodeURIComponent(devices.productName); // URL encode the product name
    navigate(`/product-details/id=${devices.productId}/name=${encodedProductName(devices)}`, {
      state: { product: devices },
    });
  };

  // const handleNavigateDetails = devices => {
  //   navigate(`/product-details/id=${devices.productId}/name=${devices.productName}`, { state: { product: devices } });
  // };

  //  useEffect(() => {
  //     const getVitelIoTProduct = async () => {
  //       const IoTProductDetails = await vitelWirelessAxios.get(
  //         `/products/menus/uANWTL9T4iwYjyO?locationId=5D3bVB5jz6bhxGk`
  //       );
  //       const IoTProductCategory = await vitelWirelessAxios.get(
  //         `/products/menus/uANWTL9T4iwYjyO?locationId=5D3bVB5jz6bhxGk `
  //       );

  //  useEffect(() => {
  //     const getVitelIoTProduct = async () => {
  //       const IoTProductDetails = await vitelWirelessAxios.get(
  //         `/products/menus/uANWTL9T4iwYjyO?locationId=5D3bVB5jz6bhxGk`
  //       );
  //       const IoTProductCategory = await vitelWirelessAxios.get(
  //         `/products/menus/uANWTL9T4iwYjyO?locationId=5D3bVB5jz6bhxGk `
  //       );

  //       console.log("IoTProductDetails", DataIoTProductDetails);

  //   };
  //     getVitelIoTProduct();
  //   }, []);

  useEffect(() => {
    const getVitelIoTProduct = async () => {
      const IoTProductDetails = await vitelWirelessAxios.get(
        `/products/menus/${orderMenuId}?locationId=${orderLocationId}`
      );

      const IoTProductCategory = await vitelWirelessAxios.get(
        `/products/menus/${orderMenuId}?locationId=${orderLocationId}`
      );

      const DataIoTProductDetails = IoTProductDetails.data;

      //   const dataAllProducts = DataIoTProductDetails.
      //   products
      //   .filter((item) => item.productDetailImages
      // );

      const subCategoryImages =
        DataIoTProductDetails.products &&
        DataIoTProductDetails.products
          .filter(product => product.productDetailImages && product.productDetailImages.length > 0)
          .flatMap(product => product.productDetailImages);

      // console.log('000000000000000>>>>>>>>>', subCategoryImages);

      // const filteredProductServCategory =
      //   subCategoryImages &&
      //   subCategoryImages.filter(
      //     service =>
      //       service.categoryName == 'IoT Devices' ||
      //       service.categoryName === 'Wearables' ||
      //       service.categoryName === 'Mobile Phones'
      //   );

      console.log('000000000000000>>>>>>>>>', DataIoTProductDetails);

      const filteredProductServCategory =
        subCategoryImages &&
        subCategoryImages.filter(
          service =>
            service.categoryName == 'IoT Devices' ||
            service.categoryName === 'Wearables' ||
            service.categoryName === 'Mobile Phones'
        );

      const IoTProductServOnly =
        subCategoryImages &&
        subCategoryImages.filter(
          service =>
            service.categoryName == 'IoT Devices' ||
            service.categoryName === 'Wearables' ||
            service.categoryName === 'Mobile Phones'
        );

      // console.log("dataAllProducts>>>>>>productDetailImages>>", dataAllProducts.productDetailImages );

      const subcategoryArray = IoTProductServOnly && IoTProductServOnly.flatMap(item => item);

      if (IoTProductServOnly?.length === 0) {
        setCheckEmptyArr(true);
      } else {
        setCheckEmptyArr(false);
      }

      setVitelIoTProductCategory(filteredProductServCategory);
      setVitelIoTProduct(subcategoryArray);
    };
    getVitelIoTProduct();
  }, []);

  return (
    <div className="IoTDeviceParent">
      <div className="IoTHeaderContainer">
        <div className="IoTSecondHeaderDiv">
          <span className="IoTHeaderText">
            Do More With Vitel Wireless <hr />
          </span>
          <div className="IoTfilterDiv">
            <label htmlFor="vasFilter">filter products</label>
            <select name="vasFilter" id="" onClick={handleCategoryChange}>
              <option value="" disabled>
                Select an option
              </option>
              <option value="all">All</option>
              {/*<option value=''>Wearables</option> */}
              {categoryNameArray &&
                categoryNameArray.map((category, index) => (
                  <option key={index} value={category.categoryId}>
                    {category.categoryName}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>

      <div className="IoTSeconParent">
        {checkEmptyArr ? (
          <div className="IoTDeviceCard">
            <div className="iotImageDiv">
              <img src={noProduct} alt="" />
              <div className="iotDetailsDiv">
                <h1>No product found {/* <span className='IoTPrice'> &#8358; 0</span> */}</h1>
                <p className="IoTDescription">No product found in this category please select another category</p>
              </div>
            </div>
          </div>
        ) : (
          VitelIoTProduct &&
          VitelIoTProduct.map(devices => (
            <Link
              to={`/product-details/${devices.productId}/${encodedProductName(devices)}`}
              state={{ product: devices }}
              className="IoTDeviceCard"
              key={devices.productId}
              // onClick={() => handleNavigateDetails(devices)}
            >
              <div className="iotImageDiv">
                <img className='stepIOTimage' src={devices.displayImage} alt="" />
                <div className="iotDetailsDiv">
                  <h1>
                    {devices?.productName}{' '}
                    <span className="IoTPrice"> &#8358; {parseInt(devices?.pricePerItem).toLocaleString()}</span>
                  </h1>
                  <p className="IoTDescription">{devices?.shortDescription}</p>
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
    </div>
  );
};

export default IoTDevices;
