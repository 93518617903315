import { FaArrowsRotate } from "react-icons/fa6"
import "./wallet.css"
import { IoMdEye, IoMdEyeOff, IoMdShare } from "react-icons/io"
import { FaFileAlt, FaRegArrowAltCircleUp } from "react-icons/fa"
import { useEffect, useRef, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { GiSaveArrow } from "react-icons/gi";
import logo from '../../../../images/newLogo.png';
import { vitelWirelessAxios } from "../../../../utility/axios";
import { type } from "@testing-library/user-event/dist/type";
import { useForm } from "react-hook-form";
import { Alert, Form } from "react-bootstrap";
import { Watch } from "react-loader-spinner";
import useFectch from "../../../../utility/getFunction";
import { PaystackButton } from "react-paystack";
import { IoCheckmarkDone } from "react-icons/io5";
import moment from "moment";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import logoNew from "../../../../images/newLogo.png"
// import { html2pdf } from "html2pdf.js";
import { useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js/dist/html2pdf.bundle";

let renderCount = 0;

const SubscribersWallet = (() => {


  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN'
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('')


 const userNumberDisplay = localStorage.getItem('MSISDN');
  const subscribInfoRaw = localStorage.getItem('subscriberInformation');
  const subscribInfoProcessed = JSON.parse(subscribInfoRaw);
  const [referenceCode, setReferenceCode] = useState('');
  const [amount, setAmount] = useState("");
  const [refreshPending, setRefreshPending] = useState(false);
  const [showStateMent, setShowStateMent] = useState(false);

  const [walletTopUpPending, setWalletTopUpPending] = useState(false)
  const [walletDisplayData, setWalletDisplayData] = useState("");
  const [success, setSuccess] = useState(false);
  const [transData, setTransData] = useState([]);


  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm();
  renderCount++;
  const inputValue = Watch('amount', '');

  const [count, setCount] = useState(0);

useEffect(() => {
  const timer = setTimeout(() => setCount(count + 1), 1000);
  return () => clearTimeout(timer); 
}, []);

  const [show, setShow] = useState(false);
  const [showTopupModal, setTopupModel] = useState(false);

  const [showAmount, setShowAmount] = useState(false);
  const [allWalletData, setAllWalletData] = useState("");
  const [walletDatum, setWalletDatum] = useState("");
  const [maximumAmountText, setMaximumAmountText] = useState("")
  const [convertedAmount, setConvertedAmount] = useState(0);
  const options = {  maximumFractionDigits: 2   }   

  const handleShowAmount = (() => {
    setShowAmount(!showAmount)
  })
  const handleShowTopupModal = () => {
    setTopupModel(true)
  }
  const handleHideTopupModal = () => {

    setTopupModel(false)
  }

  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const getData = {
      userId: subscribInfoProcessed?.customerId,
      phoneNumber: userNumberDisplay,
      sourceType: 'web',
    };
         
    setIsPending(true)
    vitelWirelessAxios.post('/payments/getWalletLedgerData', getData)
      .then(response => {

        if (!response.statusText === "OK") {
          throw Error("Could not fetch data")
        }else{
          setIsPending(false)
        }
        setIsPending(false)
        setWalletDatum(response.data)
        // setData(response.data)

        setError(null)
        // console.log("data>>>>>>>>>",data)

      })
      .catch(error => {
        setError(error.message)
        setIsPending(false)
        console.log(error)
      })
  }, []);
  
  // console.log("walletDatum>>>>>>>", walletDatum)
  
  // const { data: walletDatum, isPending: walletPending, error: walletError } = useFectch(`/payments/getWalletLedgerData/${subscribInfoProcessed.customerId}`);

  const handleRefreshWallet = async () => {
    setRefreshPending(true)
    const getData = {
      userId:subscribInfoProcessed?.customerId,  
      phoneNumber: userNumberDisplay,
      sourceType: 'web',
    }
    const wallets = await vitelWirelessAxios.post(
      '/payments/getWalletLedgerData', getData
    );
    if (wallets) {
      setRefreshPending(false)
    }
    setAllWalletData(wallets.data)
    
  };

  useEffect(() => {
    setAllWalletData(allWalletData)
  }, [allWalletData + 1])


  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setWalletDisplayData(data)
  

  }

  const handleAmount = (e) => {
    setAmount(e.target.value)
    // if(amount > 50000){
    //   setMaximumAmountText("Maximum amount exceeded")
    // }
  }



  const walletDatas = walletDatum.walletData

  const handleSubmitWalletAmount = async data => {
    setWalletTopUpPending(true);
    const walletSendData = {
      userId: subscribInfoProcessed?.customerId,
      type: 'credit',
      amount: +amount,
      phoneNumber: userNumberDisplay,
      paidFor: 'topUp',
      creditType: 'card',
      billApplication: 'vitelSubscriberApp',
      billCode: 'vs001',
      walletId: walletDatas?.walletId,
      sourceType: 'web',
    };
    // console.log("walletData>>>", walletSendData)
      await vitelWirelessAxios
      .post('/payments/createWalletLedger',walletSendData)
      .then(res => {
        // console.log('sucessData setWalletTopUpPending', res);
        setWalletTopUpPending(false)
        setTopupModel(false);
        setSuccess(true)

      })
      .catch(err => {
        console.log('err', err);
        setWalletTopUpPending(false)
      });
  };

  const lastCustomerLedger = allWalletData.lastCustomerLedger || walletDatum.lastCustomerLedger;
  const CustomerLedger = allWalletData.ledgerRecords || walletDatum.ledgerRecords;
  //  console.log("CustomerLedger>>>", CustomerLedger)
    const handlePaystackCloseAction = () => {
  };

  const handlePaystackSuccessAction = reference => {
    setReferenceCode(reference.reference);
    handleSubmitWalletAmount();
   };

  const componentProps = {
    email: subscribInfoProcessed?.emailAddress,
    amount: amount * 100,

    // metadata: {

    //   fullname: firstName,
    //   phone: phoneNumber,
    //   additionNumber,
    //   deliveryAddress,
    //   addtionInfo,
    //   state,
    //   city
    // },

    publicKey: process.env.REACT_APP_PAYSTACK_KEY,
    text: 'Fund Wallet',
    onSuccess: reference => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  useEffect(() => {

    let timeout;
    if (success) {
      timeout = setTimeout(() => {
        setSuccess(false);
      }, 5000)
    }
    return () => {
      clearTimeout(timeout);

    };

  }, [success])



  const handleShowStateMEnt = () => {
    setShowStateMent(true)
  }
  const handleGoback = () => {
    setShowStateMent(false)
  }

  const checkColorPayment = (description) => {
    switch (description) {
      case "credit":
        return "green";
      case "debit":
        return "red";
      default:
    }
  };

 useEffect(()=>{
  setTransData(CustomerLedger);

 },[CustomerLedger + 1]) 

const filterTransactionType = ((e)=>{
    
const transValue = e.target.value;

const filteredTransData = CustomerLedger && CustomerLedger.filter((data)=>(
               data.type === transValue
))
setTransData(filteredTransData);   
// console.log("filterTransactionType>>>", filteredTransData)

 }) 
 
 const filterTransactions = (transactions, months) => {
  const now = new Date();
  return CustomerLedger && CustomerLedger.filter(transaction => {
  const transactionDate = new Date(transaction.createdDateTime);
  const diffTime = Math.abs(now - transactionDate);
  const diffMonths = diffTime / (1000 * 60 * 60 * 24 * 30);
  return diffMonths <= months;
  });
  };
  
  const filterLastMonth = CustomerLedger => filterTransactions(CustomerLedger, 1);
  const filterLastTwoMonths = CustomerLedger => filterTransactions(CustomerLedger, 2);
  const filterLastSixMonths = CustomerLedger => filterTransactions(CustomerLedger, 6);
  
   
  // Use the filter functions:
  
  const lastMonthTransactions = filterLastMonth(CustomerLedger);
  const lastTwoMonthsTransactions = filterLastTwoMonths(CustomerLedger);
  const lastSixMonthsTransactions = filterLastSixMonths(CustomerLedger);
  
  // console.log('Transactions in the last month:', lastMonthTransactions);
  // console.log('Transactions in the last two months:', lastTwoMonthsTransactions);
  // console.log('Transactions in the last six months:', lastSixMonthsTransactions);

const handleTransactionPeriod = ((e)=>{
const changeDate = e.target.value
   if(changeDate === "oneMonth" ){
     setTransData(lastMonthTransactions)
   }else if(changeDate === "twoMonth"){
    setTransData(lastTwoMonthsTransactions)
   }else if(changeDate === "sixMonth"){
    setTransData(lastTwoMonthsTransactions)
   }else if(changeDate === "all"){
    setTransData(CustomerLedger)
   }
   
})


// Function to download the table as an HTML file
const tableRef = useRef(null);

  // Function to generate and download PDF
  // const downloadPDF = () => {
  //   const element = tableRef.current;
  //   if (!element) {
  //     console.error("Table not found!");
  //     return;
  //   }

  //   html2pdf()
  //     .from(element)
  //     .set({
  //       margin: 10,
  //       filename: "receipt.pdf",
  //       image: { type: "jpeg", quality: 0.98 },
  //       html2canvas: { scale: 2 },
  //       jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }
  //     })
  //     .save();
  // };




  const generatePDF = async () => {
    const element = tableRef.current;
    if (!element) {
      // console.error("Table not found!");
      return;
    }

    // Generate PDF as a Blob
    const pdfBlob = await html2pdf()
      .from(element)
      .set({
        margin: 10,
        filename: "receipt.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }
      })
      .outputPdf("blob");

    return pdfBlob;
  };

  // Function to download PDF
  const downloadPDF = async () => {
    const pdfBlob = await generatePDF();
    if (!pdfBlob) return;

    const url = URL.createObjectURL(pdfBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "receipt.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // Function to share PDF
  const sharePDF = async () => {
    const pdfBlob = await generatePDF();
    if (!pdfBlob) return;

    // Check if Web Share API is available
    if (navigator.canShare && navigator.canShare({ files: [new File([pdfBlob], "receipt.pdf", { type: "application/pdf" })] })) {
      try {
        await navigator.share({
          title: "Receipt",
          text: "Here is your receipt.",
          files: [new File([pdfBlob], "receipt.pdf", { type: "application/pdf" })],
        });
        // console.log("Shared successfully!");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Web Share API not supported in this browser.");
    }
  };


  return (
       <>
      {
        showStateMent && showStateMent ?
          <div>
            <div onClick={handleGoback} className="fullSetBack">  <MdOutlineKeyboardBackspace size={40} /> </div>


            <div className="transactionHeader">
          
            <div className="usageOptionDiv">
              <label htmlFor="" className="usageLabel">
                Filter By
              </label>
              <select onChange={filterTransactionType} >
                <option disabled>Transaction type</option>
                <option value="credit">Credit</option>
                <option value="debit">Debit</option>
               
              </select>
           
          </div>

          <div className="historyOptionDiv">
            <div className="usageOptionDiv">   
              <label htmlFor="" className="usageLabel">
               Filter Transaction Period
              </label>
              <select onChange={handleTransactionPeriod}>
                <option disabled>Transaction period</option>
                <option value="all">All</option>
                <option value="oneMonth">Past one month</option>
                <option value="twoMonth">Past two months</option>
                <option value="sixMonth">Past six months</option>
              </select>
            </div>
           
          </div>
        </div>

            <div className="recenttranStext">Transaction History</div>
            <table className="tableInerSet">

              <thead>
                <tr>
                  <th scope="col">Trans Type</th>
                  <th scope="col">Balance Before</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Current Balance</th>
                  <th scope="col">Date</th>
                  <th scope="col">Paid for</th>
                  <th scope="col">Details</th>
                </tr>
              </thead>
            <tbody>
                {
                  transData && transData.map((data) => (
                    <tr key={data.walletLedgerId}>
                      <td data-label="Trans Type" ><span style={{ color: `${checkColorPayment(data.type)}`, fontWeight:"bold" }}>  {data.type}</span></td>
                      <td data-label="Balance Before" >{symbol}{  Intl.NumberFormat("en-US",options).format(data.preBalance  ) } </td>
                      <td data-label="Amount">{symbol}{ Intl.NumberFormat("en-US",options).format(data.amount )  } </td>
                      <td data-label="Current Balance">{symbol}{  Intl.NumberFormat("en-US",options).format(data.currentBalance )} </td>
                      <td data-label="Date">
                        {moment(data.createdDateTime).format('lll')}
                      </td>
                      <td data-label="Status"
                      //  className="comSample"
                      >{data.paidFor}</td>
                      <td data-label="Details"> <IoMdEye size={30} onClick={() => handleShow(data)} /> </td>
                    </tr>
                    ))
                }
              </tbody>
            </table>
            {
              transData && transData.length === 0 && <div className="imgSideEmptydiv">
                <div > <img className="imgSideEmptygif" src="https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExdmJ6c2R5Y3NjNndtbTA2M2JpczZjNXFvNHYxYTh0aGQzNzA0emR5OSZlcD12MV9naWZzX3NlYXJjaCZjdD1n/Km2YiI2mzRKgw/200.webp"/> </div>
               <h4>No Transaction History  Found   </h4>
               <p> Your transaction history will appear here</p> 
                 </div>
            }
          </div>
          :
          <div>
            {
              success &&
              <div>   <Alert className="waletALert"> <i><IoCheckmarkDone size={25} /></i> You have succesfully funded your Vitel Wireless Wallet</Alert> </div>
            }


            <div className="CoverWallet-top">
              <div className="walletTopH-Div">
                <div className="walletTopH"> <h4> My Wallet</h4>  </div>
                <div className="wTuRn" onClick={handleRefreshWallet}>
                  {
                    refreshPending ? <span class="loaderRefresh"></span> : <FaArrowsRotate size={25} />
                  }
                </div>
              </div>
              <div className="w-FullDiv">
                <div className="W-amountDivAll">
                  <p>Vitel Wireless Wallet Balance</p>

                  {
                    showAmount ?
                      <div className="W-amountDiv">
                        <div className="W-amount"> {symbol}{ Intl.NumberFormat("en-US",options).format(lastCustomerLedger?.currentBalance || 0.00)   }</div>
                        <div className="w_Eye"> <IoMdEyeOff size={25} onClick={handleShowAmount} />  </div>
                      </div> : <div className="W-amountDiv">
                        <div className="W-amount"> XXXXXX</div>
                        <div className="w_Eye"> <IoMdEye size={25} onClick={handleShowAmount} />  </div>
                      </div>
                  }


                </div>

                {/* <div className="W-PEndingDiv">
                  <p>Pending </p>
                  <div className="W-amount">0.00</div>
                </div> */}
              </div>

              <div className="allTopUPDiv">
                <div className="eachStatemt" onClick={handleShowStateMEnt}>
                  <i> <FaFileAlt size={20} /></i>
                  <p >View Statement</p>
                </div>

                <div className="caRRy_top" onClick={handleShowTopupModal}>
                  <i> <FaRegArrowAltCircleUp size={20} /> </i>
                  <p >Top up Wallet</p>
                </div>
              </div>
            </div>
            <div className="recenttranStext">Recent Transactions</div>


            <table className="tableInerSet">

              <thead>
                <tr>
                  <th scope="col">Trans Type</th>
                  <th scope="col">Balance Before</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Current Balance</th>
                  <th scope="col">Date</th>
                  <th scope="col">Paid for</th>
                  <th scope="col">Details</th>
                </tr>
              </thead>
              <tbody>
                {
                  CustomerLedger && CustomerLedger.map((data) => (
                    <tr key={data.walletLedgerId}>
                      <td data-label="Trans Type" > <span style={{ color: `${checkColorPayment(data.type)}`, fontWeight:"bold" }}>  {data.type}</span> </td>
                      <td data-label="Balance Before" > {symbol}{  Intl.NumberFormat("en-US",options).format(data.preBalance  )  } </td>
                      <td data-label="Amount"> {symbol}{Intl.NumberFormat("en-US",options).format(data.amount) } </td>
                      <td data-label="Current Balance"> {symbol}{Intl.NumberFormat("en-US",options).format(data.currentBalance) } </td>
                      <td data-label="Date">
                        {moment(data.createdDateTime).format('lll')}
                      </td>
                      <td data-label="Status">{data.paidFor}</td>
                      <td data-label="Details"> <IoMdEye size={30} onClick={() => handleShow(data)} /> </td>
                    </tr>

                  ))
                }
              </tbody>
            </table>
{
  CustomerLedger && CustomerLedger.length === 0 &&
  <div className="imgSideEmptydiv">
               
               <h4>No Recent Transaction Found   </h4>
               <p> Your recent transactions will appear here</p> 
                 
                  </div>
}

          </div>
      }





      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"

      >
       
        <Modal.Body>
    <div id="invoice-POS">
     <div  id="download-receipt" ref={tableRef}>
   
    <div className="loguDiv" style={{justifyContent:"center", textAlign:"center", margin:" auto 0"}}>  <img className="logu" style={{width:'80px' }}  src={logoNew}/>
    <h2 className="divHeadvitel" style={{textAlign:"center"}}>  Vitel Wireless</h2>
     </div>
   
        <h2 className="divHeadetail">Transaction Details</h2>
      <div id="bot">

					<div id="table" >
						<table  >
							{/* <tr class="tabletitle">
								<td class="item"><h2>Item</h2></td>
								<td class="Hours"><h2>Qty</h2></td>
								<td class="Rate"><h2>Sub Total</h2></td>
							</tr> */}

							<tr className="service">
								<td className="tableitem"><p className="itemtext">Serial number</p></td>
								<td className="tableitem"><p className="itemtext">{walletDisplayData.walletLedgerId}</p></td>
							
							</tr>

							<tr className="service">
								<td className="tableitem"><p className="itemtext">Date</p></td>
								<td className="tableitem"><p className="itemtext">  {moment(walletDisplayData.createdDateTime).format('lll')}</p></td>
							
							</tr>

							<tr className="service">
								<td className="tableitem"><p className="itemtext">Type</p></td>
								<td className="tableitem"><p className="itemtext">{walletDisplayData.type}</p></td>
						
							</tr>

							<tr className="service">
								<td className="tableitem"><p className="itemtext">Amount</p></td>
								<td className="tableitem"><p className="itemtext"> {symbol}{  Intl.NumberFormat("en-US",options).format(walletDisplayData.amount) }</p></td>
							
							</tr>

							<tr className="service">
								<td className="tableitem"><p className="itemtext">Balance Before</p></td>
								<td className="tableitem"><p className="itemtext"> {symbol}{   Intl.NumberFormat("en-US",options).format(walletDisplayData.preBalance)}</p></td>
						
							</tr>


              <tr className="service">
								<td className="tableitem"><p className="itemtext">Current Balance</p></td>
								<td className="tableitem"><p className="itemtext">{symbol}{  Intl.NumberFormat("en-US",options).format(walletDisplayData.currentBalance )} </p></td>
							
							</tr>

							<tr className="service">
								<td className="tableitem"><p className="itemtext">Phone Number</p></td>
								<td className="tableitem"><p className="itemtext">{subscribInfoProcessed?.phoneNumber}</p></td>
							
							</tr>

							<tr className="service">
								<td className="tableitem"><p className="itemtext">Paid For</p></td>
								<td className="tableitem"><p className="itemtext">   {walletDisplayData?.paidFor}</p></td>
							
							</tr>
         
              <tr className="service">
								<td className="tableitem"><p className="itemtext">Credit Type</p></td>
								<td className="tableitem"><p className="itemtext"> {walletDisplayData?.creditType} </p></td>
								
							</tr>

							<tr className="service">
								<td className="tableitem"><p className="itemtext">Bill Application</p></td>
								<td className="tableitem"><p className="itemtext"> {walletDisplayData?.billApplication} </p></td>
						
							</tr>

							<tr className="service">
								<td className="tableitem"><p className="itemtext">Bill code</p></td>
								<td className="tableitem"><p className="itemtext">     {walletDisplayData?.billCode}</p></td>
								
							</tr>



						</table>
					</div>
          </div>
          </div>

					<div id="legalcopy">
          <div className="backToHome">
              <div className="saveIconDiv" onClick={downloadPDF}>
                <span className="shareIconspan">
                  <GiSaveArrow className="saveIcon" />
                </span>
                <span>Download Receipt</span>
              </div>
              <div className="shareIconDiv saveIconDiv2" onClick={sharePDF}>
                <span className="shareIconspan">
                  <IoMdShare className="saveIcon" />
                </span>
                <span>Share Receipt</span>
              </div>

              <button className="getBackBtn" onClick={handleClose} >
                Close
              </button>
            </div>
					</div>

			
             </div>
          </Modal.Body>

      </Modal>


      <Modal
        show={showTopupModal}
        onHide={handleHideTopupModal}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="headBOsttap">Please enter the amount you wish to add to your wallet in the box below.</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit((data, event) => {
          // event.target.reset()
          // console.log('Address>>>>>>', data);

        })}>
          <Modal.Body>

            <input type="number" placeholder="Amount to pay" className="fmPushInputamount" onChange={handleAmount} />
              <div> Min {symbol}50 <span className="ml-3"> Max {symbol}50,000</span>  </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleHideTopupModal}>
              Cancel
            </Button>
            <PaystackButton
              disabled={amount < 50 || amount > 50000}
              className="WProceedBtn"
              {...componentProps}

            />
      
          </Modal.Footer>
        </Form>
      </Modal>    



    </>
  )
})

export default SubscribersWallet