import React, { useState, useEffect } from 'react';

import topUpImg from '../../images/mobileview.jpg';
import { PaystackButton } from 'react-paystack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { vitelWirelessAxios } from '../../utility/axios';
import './AirTimeTopConfirmsecond.css';
import useFectch from '../../utility/getFunction';
import { Button, Modal } from 'react-bootstrap';

const AirTimeTopConfirm = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const { amount, MSISDN, subscriberInfo } = location.state || {};
  // const [allowRecharge, setAllowRecharge] = useState(false);
  const [airTimeError, setAirtimeErro] = useState(false);
  const [payReference, setPayReference] = useState();
  const [pendingWalletRecharge, setPendingWalletRecharge] = useState(false);
  const [creditType, setCreditType] = useState('');
  const [showLessBalance, setShowLessBalance] = useState(false);
  const [showLessMoney, setShowLessMoney] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [ShowRecharge, setShowRecharge] = useState(false);
  var airTimeTopUpObj;

  const userNumberDisplay = localStorage.getItem('MSISDN');
  const subscribInfoRaw = localStorage.getItem('subscriberInformation');
  const subscribInfoProcessed = JSON.parse(subscribInfoRaw);
  const [walletData, setWalletData] = useState('');
  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [isPendingData, setIsPendingData] = useState(false);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState('voice');
  const [creditWalletNoKyc, setCreditWalletNoKyc] = useState(false);
  const [mainCreditWalletNoKyc, setMainCreditWalletNoKyc] = useState(false);

  useEffect(() => {
    const getData = {
      userId: subscribInfoProcessed?.customerId,
      phoneNumber: userNumberDisplay,
      sourceType: 'web',
    };

    setIsPending(true);
    vitelWirelessAxios
      .post('/payments/getWalletLedgerData', getData)
      .then(response => {
        if (!response.statusText === 'OK') {
          throw Error('Could not fetch data');
        } else {
          setIsPending(false);
        }
        setIsPending(false);
        setWalletData(response.data);
        // setData(response.data)

        setError(null);
        // console.log("data>>>>>>>>>",data)
      })
      .catch(error => {
        setError(error.message);
        setIsPending(false);
        console.log(error);
      });
  }, []);

  const handleSetShowRecharge = () => {
    setShowRecharge(true);
  };
  const handleHideShowRecharge = () => {
    setShowRecharge(false);
  };

  // const { data: walletData, isPending: walletPending, error: walletError } = useFectch(`/payments/getWalletLedgerData/${subscribInfoProcessed?.customerId}`);

  console.log('subscribInfoProcessed', subscribInfoProcessed);

  useEffect(() => {
    document.title = amount ? `Vitel Wireless | ${amount} Airtime top up` : 'Airtime top up';
  }, [amount]);

  const handleNavigateAccount = () => {
    if (subscribInfoRaw) {
      navigation('/customer/account/subscriber');
    } else {
      navigation('/subscriber-login');
    }
  };

  const CustomerLedger = walletData?.lastCustomerLedger;

  const handleShowLessMoney = () => {
    setShowLessMoney(true);
  };
  const handleHideLessMoney = () => {
    setShowLessMoney(false);
  };
  const handlehideLogin = () => {
    setShowLogin(false);
  };

  const handlehideCreditWalletNoKyc = () => {
    setCreditWalletNoKyc(false);
  };
  const handlehideMainCreditWalletNoKyc = () => {
    setMainCreditWalletNoKyc(false);
  };

  const handleCheckBalance = data => {
    handleHideShowRecharge();
    if (subscribInfoRaw && CustomerLedger) {
      if (CustomerLedger?.currentBalance < amount) {
        setShowLessMoney(true);
      } else {
        handleSubmitPortaoneBilling();
        // handleSubmitWalletRecharge();
      }
    } else if (subscribInfoRaw && !CustomerLedger) {
      setShowLessMoney(true);
    } else {
      setShowLogin(true);
    }
  };

  const handleOtionChange = event => {
    setSelectedOption(event.target.value);

    console.log('event.target.value', selectedOption);
  };

  const handSubscriberInfo = reference => {
    setPayReference(reference.reference);
    const payStackObj = {
      MSISDN: MSISDN,
      productId: 'ZK' + amount,
      amount: amount,
      userName: MSISDN,
      paystackPaymentReference: reference.reference,
    };

    airTimeTopUpObj = payStackObj;
  };
  console.log('sendRecord>>>>>', CustomerLedger?.currentBalance);

  // Debit Vitel wallet when the number has not done KYC
  const handleSubmitWalletDebit = async data => {
    setIsPendingData(true);
    setPendingWalletRecharge(true);
    const walletSendData = {
      userId: subscribInfoProcessed?.customerId,
      type: 'debit',
      amount: +amount,
      rechargeType: selectedOption,
      phoneNumber: subscribInfoProcessed?.phoneNumber,
      paidFor: 'rechargeCard',
      creditType: 'vitelWallet',
      billApplication: 'vitelSubscriberApp',
      billCode: 'vs001',
      walletId: CustomerLedger?.walletId,
      sourceType: 'web',
    };

    console.log('walletSendDataNOOO >>>>>', walletSendData);

    await vitelWirelessAxios
      .post('/payments/createWalletLedger', walletSendData)
      .then(res => {
        console.log('RechargeReturn', res);
        setPendingWalletRecharge(false);
        setCreditWalletNoKyc(true);
      })
      .catch(err => {
        console.log('err', err);
        setPendingWalletRecharge(false);
      });
  };

  // Credit Vitel wallet when the number has not done KYC
  const handleSubmitWalletRecharge = async data => {
    setIsPendingData(true);
    setPendingWalletRecharge(true);
    const walletSendData = {
      userId: subscribInfoProcessed?.customerId,
      type: 'credit',
      amount: +amount,
      rechargeType: selectedOption,
      phoneNumber: subscribInfoProcessed?.phoneNumber,
      paidFor: 'rechargeCard',
      creditType: 'vitelWallet',
      billApplication: 'vitelSubscriberApp',
      billCode: 'vs001',
      walletId: CustomerLedger?.walletId,
      sourceType: 'web',
    };

    console.log('walletSendDataNOOO >>>>>', walletSendData);

    await vitelWirelessAxios
      .post('/payments/createWalletLedger', walletSendData)
      .then(res => {
        console.log('RechargeReturn', res);
        setPendingWalletRecharge(false);
        setMainCreditWalletNoKyc(true);
      })
      .catch(err => {
        console.log('err', err);
        setPendingWalletRecharge(false);
      });
  };

  // Credit vitel wallet From bank
  const handleSubmitPortaoneBillingBankPayment = async data => {
    setIsPendingData(true);
    setPendingWalletRecharge(true);
    const walletSendData = {
      userId: subscribInfoProcessed?.customerId,
      type: 'debit',
      amount: +amount,
      rechargeType: selectedOption,
      phoneNumber: subscribInfoProcessed?.phoneNumber,
      paidFor: 'rechargeCard',
      creditType: 'vitelWallet',
      billApplication: 'vitelSubscriberApp',
      billCode: 'vs001',
      walletId: CustomerLedger?.walletId,
      sourceType: 'web',
    };

    console.log('walletSendDataNOOO >>>>>', walletSendData);

    await vitelWirelessAxios
      .post('/subscribers/portaone-vd-topup-account', walletSendData)
      .then(res => {
        console.log('RechargeReturn', res);
        if (res.data.success === false && res.data.message === 'Account is not Activated') {
          handleSubmitWalletRecharge();
        } else if (res.data.success === true) {
          handleSubmitWalletDebit();
          let myModal = new window.bootstrap.Modal(document.getElementById('successModal'));
          myModal.show();
          document.getElementById('modalCloseButton').addEventListener('click', () => {
            handleNavigateSuccess(amount, MSISDN, subscriberInfo);
          });
        }
        setPendingWalletRecharge(false);
      })
      .catch(err => {
        console.log('err', err);
        setPendingWalletRecharge(false);
      });
  };

  // Topup portal billing if the User has done KYC
  const handleSubmitPortaoneBilling = async data => {
    setIsPendingData(true);
    setPendingWalletRecharge(true);
    const walletSendData = {
      userId: subscribInfoProcessed?.customerId,
      type: 'debit',
      amount: +amount,
      rechargeType: selectedOption,
      phoneNumber: subscribInfoProcessed?.phoneNumber,
      paidFor: 'rechargeCard',
      creditType: 'vitelWallet',
      billApplication: 'vitelSubscriberApp',
      billCode: 'vs001',
      walletId: CustomerLedger?.walletId,
      sourceType: 'web',
    };

    console.log('walletSendDataNOOO >>>>>', walletSendData);

    await vitelWirelessAxios
      .post('/subscribers/portaone-vd-topup-account', walletSendData)
      .then(res => {
        console.log('RechargeReturn', res);
        if (res.data.success === false && res.data.message === 'Account is not Activated') {
          setCreditWalletNoKyc(true);
        } else if (res.data.success === true) {
          let myModal = new window.bootstrap.Modal(document.getElementById('successModal'));
          myModal.show();
          document.getElementById('modalCloseButton').addEventListener('click', () => {
            handleNavigateSuccess(amount, MSISDN, subscriberInfo);
          });
        }
        setPendingWalletRecharge(false);
      })
      .catch(err => {
        console.log('err', err);
        setPendingWalletRecharge(false);
      });
  };

  const handleCreateWallet = async () => {
    const formattedPhoneNumber = MSISDN.startsWith('0') ? `234${MSISDN.slice(1)}` : MSISDN;
    try {
      const userId = { userId: subscriberInfo.customerId, phoneNumber: formattedPhoneNumber, sourceType: 'web' };

      // Create Wallet
      const createWalletRes = await vitelWirelessAxios.post(`/payments/createWallet`, userId);
      // console.log('client number', createWalletRes);

      const userWalletData = {
        userId: subscriberInfo?.customerId,
        phoneNumber: formattedPhoneNumber,
        sourceType: 'web',
      };

      const walletLedgerRes = await vitelWirelessAxios.post('/payments/getWalletLedgerData', userWalletData);
      // console.log('wallet information', walletLedgerRes);

      // Ensure walletId exists before proceeding
      if (!walletLedgerRes.data?.walletData.walletId) {
        console.error('Error: Wallet ID is missing');
        return;
      }

      // Credit Wallet
      const creditWalletData = {
        userId: subscriberInfo?.customerId,
        type: 'credit',
        amount: +amount,
        phoneNumber: MSISDN,
        paidFor: 'rechargeCard',
        creditType: 'card',
        billApplication: 'vitelSubscriberApp',
        billCode: 'vs001',
        walletId: walletLedgerRes.data.walletData.walletId,
        sourceType: 'web',
      };

      // console.log('walletData>>>', creditWalletData);

      const creditWalletRes = await vitelWirelessAxios.post('/payments/createWalletLedger', creditWalletData);
      // console.log('sucessData setWalletTopUpPending', creditWalletRes);

      // Uncomment if needed:
      // setWalletTopUpPending(false);
      // setTopupModel(false);
      // setSuccess(true);
    } catch (err) {
      console.error('Error in handleCreateWallet:', err);
      // setWalletTopUpPending(false);
    }
  };

  const handlePaystackSuccessAction = reference => {
    handSubscriberInfo(reference);
    handleCreateWallet();
    handleSubmitPortaoneBillingBankPayment();
    // setLoadingButton(true);
    // console.log('allValue', airTimeTopUpObj);
    // const payStackRef = airTimeTopUpObj.paystackPaymentReference;
    // await vitelWirelessAxios
    //   .post('/generals/handleTopUpCredit', airTimeTopUpObj)
    //   .then(res => {
    //     console.log('success topup message', res);
    //     let myModal = new window.bootstrap.Modal(document.getElementById('successModal'));
    //     myModal.show();
    //     document.getElementById('modalCloseButton').addEventListener('click', () => {
    //       handleNavigateSuccess(amount, MSISDN, subscriberInfo, payStackRef);
    //     });
    //   })
    //   .catch(err => {
    //     setAirtimeErro(true);
    //     console.log('top up error');
    //   });
  };

  // console.log('amount, MSISDN, subscriberInfo', amount, MSISDN, subscriberInfo);

  const handleCancel = () => {
    let myModal = new window.bootstrap.Modal(document.getElementById('successModal'));
    myModal.hide();
  };

  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // setEmail('')
    //allValue = " ";
  };

  //

  const config = {
    reference: new Date().getTime().toString(),
    email: subscriberInfo?.emailAddress,
    amount: amount * 100,
    // currency: currencyCode,
    publicKey: 'pk_test_7c1eddc66acb8a19f5d6ea061840ce18005d7ddc',

    // publicKey: "pk_live_b3304b8274925a6dd5479dc6972a3ce6adde2396",
  };

  const componentProps = {
    ...config,
    text: 'Recharge With Bank',
    onSuccess: reference => {
      handlePaystackSuccessAction(reference);
    },
    onClose: handlePaystackCloseAction,
  };

  const handleNavigateSuccess = (amount, MSISDN, subscriberInfo, payReference) => {
    navigation(`/quick-topup-confirmation`, {
      state: {
        amount: amount,
        MSISDN: MSISDN,
        subscriberInfo: subscriberInfo,
        paystackPaymentReference: payReference,
      },
    });
  };

  const navigateBack = () => {
    navigation(-1);
  };

  return (
    <div className="parentAirtimeTopup">
      <div className="secondLayerTopUp">
        <div className="topupIMGDiv">
          <img src={topUpImg} alt="" />
        </div>
        <div className="topUpMainDiv">
          <h3>Air time top up request details</h3>
          <table className="table ">
            <thead></thead>
            <tbody>
              {/* <tr>
                <th scope="row">Full Name</th>
                <td>
                  {subscriberInfo?.lastName} {subscriberInfo?.firstName}
                </td>
              </tr>
              <tr>
                <th scope="row">Email</th>
                <td>{subscriberInfo?.emailAddress}</td>
              </tr> */}
              <tr>
                <th scope="row">Phone Number</th>
                <td>{MSISDN}</td>
              </tr>
              <tr>
                <th scope="row">Amount</th>
                <td colspan="2">{amount}</td>
              </tr>
              <tr>
                <th scope="row">Discount</th>
                <td colspan="2">0</td>
              </tr>
              <tr>
                <th scope="row">Total</th>
                <td colspan="2">{amount}</td>
              </tr>
            </tbody>
          </table>

          <div className="container-con-Contain">
            <div className="btn-container side-btn-container">
              <input
                type="radio"
                id="r_lorem"
                name="radio_choices"
                value="voice"
                checked={selectedOption === 'voice'}
                onChange={handleOtionChange}
              />
              <label className="labVoice" for="r_lorem">
                VOICE
              </label>
              <br />
              <input
                type="radio"
                id="r_ipsum"
                name="radio_choices"
                value="data"
                checked={selectedOption === 'data'}
                onChange={handleOtionChange}
              />
              <label className="labVoice" for="r_ipsum">
                DATA
              </label>
              <br />
            </div>
          </div>

          <div className="setDivSENdtop">
            <div className="payNowDiv">
              <button className="getStartedBtn rechargewithVite" onClick={handleSetShowRecharge}>
                {' '}
                Recharge with Vitel Wallet
              </button>
            </div>
            <div className="payNowDiv">
              <PaystackButton
                className="getStartedBtn"
                {...componentProps}
                // disabled={isFieldsEmpty()}
              />
            </div>
          </div>
          <div className="payNowDivBTn">
            <button className="btn btn-secondary" onClick={navigateBack}>
              {' '}
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="successModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Airtime Top up Successful
              </h1>
            </div>
            {airTimeError ? (
              <div className="modal-body">
                Your airtime top-up of <span>₦ {amount}</span> to <span className="errorMessage">{MSISDN}</span> was not
                successful.
              </div>
            ) : (
              <div className="modal-body">
                Your airtime top-up of ₦ {amount} to <span className="errorMessage">{MSISDN}</span> was successful.
              </div>
            )}

            <div className="modal-footer">
              <button
                onClick={() => handleNavigateSuccess(amount, MSISDN, subscriberInfo, payReference)}
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                id="modalCloseButton"
              >
                View Receipt
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                id="modalCloseButton"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={ShowRecharge}
        onHide={handleHideShowRecharge}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: 'center', fontWeight: 'bold' }}>Pay Via Vitel Wireless Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            The amount for this transaction will be deducted from your Vitel Wireless wallet balance. Are you sure you
            want to proceed?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHideShowRecharge}>
            Cancel
          </Button>

          {isPendingData ? (
            <Button className="WProceedBtn">
              <i className=""></i>
              <span class="loaderButton" id="searcMob-btn"></span> Loading...
            </Button>
          ) : (
            <Button
              className="WProceedBtn"
              onClick={() => {
                handleCheckBalance();
              }}
            >
              Proceed
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showLessMoney}
        onHide={handleHideLessMoney}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="headBOsttap insTred">Insufficient balance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Your wallet balance is insufficient for the requested amount. Please add funds to your wallet and try again.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHideLessMoney}>
            Cancel
          </Button>

          <Button className="WProceedBtn" onClick={handleNavigateAccount}>
            Fund Wallet
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showLogin}
        onHide={handlehideLogin}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: 'bold' }}>Request Login </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>You need to log in to use the wallet. Please log in and try again.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlehideLogin}>
            Cancel
          </Button>
          <Button className="WProceedBtn" onClick={handleNavigateAccount}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={creditWalletNoKyc}
        onHide={handlehideCreditWalletNoKyc}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: 'bold', color: 'red' }}> NO KYC, Account Not Credited </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            You haven't completed your KYC process yet. Please ensure you finish the KYC verification and then try again
            to proceed with your request.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlehideCreditWalletNoKyc}>
            Cancel
          </Button>
          {/* <Button className="WProceedBtn" onClick={handleNavigateAccount}>
            Continue
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal
        show={mainCreditWalletNoKyc}
        onHide={handlehideMainCreditWalletNoKyc}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: 'bold' }} className="text-info">
            {' '}
            NO KYC, Vitel Wallet Credited{' '}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            You haven't completed your KYC process yet. Please ensure you finish the KYC verification and then try again
            to proceed with your request.
            <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Your Vitelwireless wallet has been credited</p>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlehideMainCreditWalletNoKyc}>
            Cancel
          </Button>
          {/* <Button className="WProceedBtn" onClick={handleNavigateAccount}>
            Continue
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AirTimeTopConfirm;
