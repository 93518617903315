import React, { useEffect } from 'react';
import './BecomePartner.css';
import back from '../../images/back.jpg';
import PartnerFunctions from './PartnerFunctions/PartnerFunctions';
import PartnerForm from './PartnerForm/PartnerForm';
import { Link } from 'react-router-dom';
import Partners from '../partner/partner';

const BecomePartner = () => {
    const scrolltop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
  useEffect(() => {
    document.title = 'Vitel Wireless | become a partner';
    scrolltop();
  }, []);

  return (
    <div className="parentPartnerDiv">
      <div className="partnerHerosection">
        <div className="partnerherotex">
          <div className="herotitletext">
            <span className="joinspan">
              Join the <span className="mission">mission</span>
            </span>
            <span className="doublespan">
              <span className="doublehus">Doubling</span> Your Hustle
            </span>
            <span className="payspan">
              for <span className="doublepay">Double</span> the Pay
            </span>
          </div>
          <div className="partnerHeroBtn">
            <Link className="linkBuuton" to="/become-vitel-partner/partner-request-form">
              Register Your Interest
            </Link>
          </div>
        </div>   
        <div className="partnerheroimg">
          <img
            src="https://img.freepik.com/free-vector/international-business-cooperation-businesswoman-businessman-shaking-hands-global-collaboration-agreement-international-partnership-concept-illustration_335657-2033.jpg"
            alt=""
          />
        </div>
      </div>
      <PartnerFunctions />
    
        <Partners/>
       <section></section>
    </div>
  );
};

export default BecomePartner;
