import { useNavigate } from 'react-router-dom';
import Footer from '../footer';
import HomeNavComponent from '../home-nav-component';
// import "./reserve-number.css";
import './reserveNum.css';
import { FaCartPlus } from 'react-icons/fa';
import LearnMoreNav from '../learnmore-nav';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useFectch from '../../utility/getFunction';
import { useDispatch } from 'react-redux';
import { addToCart, remove } from '../../redux/counter';
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { vitelWirelessAxios } from '../../utility/axios';
let renderCount = 0;
const ReserveANumber = () => {
  const [showRegular, setShowRegular] = useState(true);
  const [showPremium, setShowPremium] = useState(false);
  const [show, setShow] = useState(false);
  const [regularDefault, setRegularDefault] = useState(true);
  const [premiumDefault, setPremiumDefault] = useState(true);
  const [inputSelect, setInputSelect] = useState('');
  const [progress, setProgress] = useState(true);

  const [showRegularText, setShowRegularText] = useState('');
  const [checker, setChecker] = useState(false);
  const [cardId, setCardId] = useState();
  const [disable, setDisable] = useState(false);
  const [numberFilter, setNumberFilter] = useState(false);
  const [reshow, setReShow] = useState(true);
  const [productLoad, setProductLoad] = useState([]);
  const [numberIsPending, setNumberIsPending] = useState(false);
  const [allNumbers, setAllNumbers] = useState([]);
  const [allNumberPending, setAllNumberPending] = useState('');
  const [mainNumbers, setMainNumbers] = useState([]);
  const [numberFilterId, setNumberFilterId] = useState('');
  const [showDefaultPremiumNum, setShowDefaultPremiumNum] = useState([]);
  const [premiumFilteredData, setPremiumFilteredata] = useState([]);
  const [premiumDefaultNumbers, setPremiumDefaultNumbers] = useState(true);

  const [checkedMoreNumber, setCheckedMoreNumbers] = useState(false)

  const locationId = process.env.REACT_APP_SIM_LOCATION_ID;

  useEffect(() => {
    document.title = 'Vitel Wireless | Reverse Number';
  }, []);

  renderCount++;

  const dispatch = useDispatch();

  const handleClose = () => setShow(false);

  const navigate = useNavigate();

  const navigateLearnMore = () => {
    navigate('/learn-more');
  };

  const onchangeMoreNumbers = (() => {
    setCheckedMoreNumbers(!checkedMoreNumber)

  })

 


  const navigateReservedNumber = () => {
    navigate('/reservedNumberCart');
  };

  const HandleShowRegular = () => {
    setShowRegular(true);
    setShowPremium(false);

    const targetElement = document.getElementById('premiumSection');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const totalCount = useSelector(state => {
    return state.cart.items.length;
  });

  const handleShow = () => {
    if (totalCount === 0) {
      setShow(true);
    } else {
      navigate('/reservedNumberCart');
    }
  };

  const handleShowPremium = () => {
    setShowPremium(true);
    setShowRegular(false);
    handleShowPremiumNum();

    // Scroll down to the desired section
    const targetElement = document.getElementById('premiumSection');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // const {
  //   data: numbers,
  //   isPending: numbersIsPending,
  //   error: numbersError
  // } = useFectch('/registrations/getUploadNumbers');

  useEffect(() => {
    setProgress(true);

    vitelWirelessAxios
      .get('/products/1?productType=single', {
        headers: {
          'x-access-token':
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImNoaWRleEBnbWFpbC5jb20iLCJlbXBsb3llZUlkIjoiRG5oNnk0R2lPNjRGNXJRIiwiaWF0IjoxNzM4ODM1MTY4LCJleHAiOjE3Mzg4NzExNjh9.mu2NmnYsLEWGEx9jbEDF1E2SC8ON3yhsmLPjIl1Wldw',
          sourceType: 'web',
        },
      })
      .then(response => {
        if (response.status !== 200) {
          // statusText is not always available in some environments
          throw new Error('Could not fetch data');
        } else {
          setProgress(false);
        }
        setProgress(false);
        setMainNumbers(response.data);
      })
      .catch(error => {
        setProgress(false);
        // setAllNumberPending(false);
        console.log(error);
      });
  }, []);
  const FindProductId = mainNumbers && mainNumbers.find(product => product.productName === 'Phone Numbers Regular');

  // console.log("mainNumbers>>>", mainNumbers)
  useEffect(() => {
    // setNumberIsPending(true)
    const getData = {
      productId: FindProductId?.productId,
      locationId: locationId,
      sourceType: 'web',

      // "8dXW1Hhvb2e6OXG"
    };
    // setIsPending(true)

    vitelWirelessAxios
      .post('/registrations/getMSISDNNumbers', getData)
      .then(response => {
        setAllNumbers(response.data);
        if (!response.statusText === 'OK') {
          throw Error('Could not fetch data');
        } else {
          setNumberIsPending(false);
        }
        setNumberIsPending(false);

        // setData(response.data)
      })
      .catch(error => {
        setNumberIsPending(false);
        console.log(error);
      });
  }, [allNumbers]);


  // console.log("allnumbers>>>", allNumbers)

  // .....................

  // console.log("AllNumbers>>>>>>numberFilterId>>>", mainNumbers)

  // const update = () => {
  //   vitelWirelessAxios.get("/registrations/getUploadNumbers")
  //     .then((res) => {
  //       setProductLoad(res.data);
  //     });
  // };
  // useEffect(update, [productLoad]);

  // //  Filter regular numbers
  // const regularNumbers = numbers?.filter((num) => num.Premium === "No" && num.Availability === "Yes");
  // console.log("num.PrimumregularNumbers>>>>>", regularNumbers );
  // // Filter premium numbers
  // const PremiumNumbers = numbers?.filter((nums) => nums.Premium === "Yes" && nums.Availability === "Yes");
  // const [filteredData, setFilteredData] = useState(PremiumNumbers);

  //  Filter regular numbers
  const regularNumbers = allNumbers?.filter(num => num.price < 5000);
  // console.log("num.PrimumregularNumbers>>>>>", regularNumbers );

  // Filter premium numbers
  const PremiumNumbers = allNumbers?.filter(nums => nums.price >= 5000);
  const [filteredData, setFilteredData] = useState(PremiumNumbers);

  // console.log("PremiumNumbers>>>", PremiumNumbers)

  const shuffleArray = array => {
    let shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // const items = regularNumbers;
  // const [shuffledItems, setShuffledItems] = useState(shuffleArray(items));
  // const [displayedItems, setDisplayedItems] = useState(
  //   shuffledItems.slice(0, 6)
  // );
  const items = allNumbers;
  const [shuffledItems, setShuffledItems] = useState(shuffleArray(items));
  const [displayedItems, setDisplayedItems] = useState(shuffledItems.slice(0, 6));

  const reshuffle = () => {
    const newShuffledItems = shuffleArray(items);
    setShuffledItems(newShuffledItems);
    setDisplayedItems(newShuffledItems.slice(0, 6));
    setRegularDefault(false);
  };

  // Premium functions
  const shuffleArrayPremium = array => {
    let shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const itemsPremium = premiumFilteredData;
  const [shuffledItemsPremium, setShuffledItemsPremium] = useState(shuffleArrayPremium(itemsPremium));
  const [displayedItemsPremium, setDisplayedItemsPremium] = useState(shuffledItemsPremium.slice(0, 6));

  const reshufflePremium = () => {
    const newShuffledItems = shuffleArray(itemsPremium);
    setShuffledItemsPremium(newShuffledItems);
    // reshuffleDefaultPrem()
    setDisplayedItemsPremium(newShuffledItems.slice(0, 6));
    setPremiumDefault(false);
    setReShow(false);
  };

  const shuffleArrayPremiumDefault = array => {
    let shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const reshuffleDefaultPrem = () => {
    const newShuffledItems = shuffleArrayPremiumDefault(premiumFilteredData);
    // setShuffledItems(newShuffledItems);
    setShowDefaultPremiumNum(newShuffledItems.slice(0, 6));
    setRegularDefault(false);
  };

  let premium;
  const handleAddToCart = num => {
    if (num.price >= 5000) {
      premium = 'true';
    } else {
      premium = 'false';
    }
    const cartData = {
      serializedId: num.serializedId,
      phoneUploadId: num.serializedId,
      serialNumber: num.serialNumber,
      price: num.price,
      productName: num.productName,
      locationId: num.locationId,
      virtualValue: num.virtualValue,
      productId: num.productId,
      serialPurchaseId: num.serialPurchaseId,
      Premium: premium,
    };
    setInputSelect(!inputSelect);
    dispatch(addToCart(cartData));
    setShowRegularText(cartData);

    if (cardId === num.serializedId) {
      setChecker(true);
    } else {
      setChecker(false);
    }
  };
  const onchangeInput = e => {
    setCardId(e.target.value);
  };

  const { items: itemAll, totalAmount, hasError } = useSelector(state => state.cart);

  const checkIds = itemAll.map(num => num.serializedId);

  // useEffect(()=>{
  //   if(){
  //     setDisable(true)
  //   }
  // },[])
  const [filteredList, setFilteredList] = useState(PremiumNumbers);
  const [selectedValue, setSelectedValue] = useState('');
  const [allPremiumDefualt, setAllPremiumDefualt] = useState('');

  // const handleSelectChange = (event) => {
  //   const value = event.target.value;
  //   setSelectedValue(value);co
  //   filterList(value);
  // };

  const showPremDefault =
    mainNumbers &&
    mainNumbers
      .filter(product => product.productName.includes('Premium'))
      .map(product => ({ productId: product.productId }))[0];

  // console.log("setShowDefaultPremiumNum>>>>>", showDefaultPremiumNum)

  const handleShowPremiumNum = async () => {
    const getDefaultPrem = {
      productId: showPremDefault?.productId,
      locationId: locationId,
      sourceType: 'web',

      // "8dXW1Hhvb2e6OXG"
    };

    const allPrem = await vitelWirelessAxios.post('/registrations/getMSISDNNumbers', getDefaultPrem);
    setShowDefaultPremiumNum(allPrem.data);

    if (showDefaultPremiumNum) {
      setNumberIsPending(false);
    } else {
      setNumberIsPending(true);
    }
  };

  const handleFilterChange = event => {
    setAllNumberPending(true);
    setPremiumDefaultNumbers(false);
    const selectedValue = event.target.value;
    setPremiumDefault(true);
    setDisplayedItemsPremium(false);

    const handleChangePremium = async () => {
      // setRefreshPending(true)
      const getData = {
        productId: selectedValue,
        locationId: locationId,
        sourceType: 'web',
        // "8dXW1Hhvb2e6OXG"
      };
      const FilteredPremiumData = await vitelWirelessAxios.post('/registrations/getMSISDNNumbers', getData);
      setPremiumFilteredata(FilteredPremiumData.data);

      if (FilteredPremiumData) {
        setAllNumberPending(false);
      } else {
        setAllNumberPending(true);
      }
    };

    //   if (selectedValue === "premium1") {
    //   setFilteredData(PremiumNumbers.filter(item =>
    //        item.price === 5000
    //     ));
    //   }else if (selectedValue === "premium2") {
    //   setFilteredData(PremiumNumbers.filter(item =>
    //     item.price === 10000
    //   ));
    //   }else if (selectedValue === "premium3") {
    //   setFilteredData(PremiumNumbers.filter(item =>
    //     item.price === 15000
    //   ));
    //   } else if (selectedValue === "premium4") {
    //   setFilteredData(PremiumNumbers.filter(item =>
    //     item.price == 25000
    //   ));
    //  } else if (selectedValue === "premium5") {
    //   setFilteredData(PremiumNumbers.filter(item =>
    //     item.price == 45000
    //   ));
    //  }
    //   else {
    //   setFilteredData(PremiumNumbers);
    // }

    handleChangePremium();

    fiterteredText();
  };

  const fiterteredText = () => {
    if (filteredData) {
      setNumberFilter(true);
    } else {
      setNumberFilter(false);
    }
  };
  // console.log("filPremiumNumbers", PremiumNumbers);

  const [selectedOption, setSelectedOption] = useState('option1');

  const handleOtionChange = event => {
    setSelectedOption(event.target.value);
  };

  const navigateCorporateSolution = () => {
    navigate('/corperate-products');
  };

  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN',
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('');
  //

  return (
    <>
      {/* <LearnMoreNav /> */}

      {/* <div className="first-div container">
        <div style={{ fontWeight: "bold" }}>Intereted in selling Vital wireless in your area?</div>


        <button className="button-10" role="button" onClick={navigateLearnMore}>Learn more  </button>


        <div ><FaCartPlus size={25} onClick={navigateReservedNumber} /> </div>
      </div> */}

      {/* <HomeNavComponent /> */}

      <div className=" ktReserveback">
        <div className="backsetorder2">
          <div className="backset2">
            <div className="container2">
              <h2 className="reserVHead2">
                Reserve
                <br />
                <span className="yourNumber">Your Number</span> Now
              </h2>
              <div>
                <p className="reserVtxt2">
                  Thank you for choosing Vitel Wireless! We're revolutionizing mobile
                  telecommunications with innovative services and seamless connectivity. Book your preferred
                  phone number Now!, and we'll get your physical SIM card ready and delivered to you!
                </p>
                <div className="optionsBtnDiv">
                  <fieldset>
                    <legend> Would you like a premium?
                      Get a premium number Today For a fee.</legend>

                    <div class="form__group">
                      

                      {regularNumbers && regularNumbers.length > 0 && (
                       <>
                    <input
                    onClick={HandleShowRegular}
                    checked={selectedOption === 'option1'}
                    name="groupt1"
                    value="option1"
                    id="radio1"
                    type="radio"
                    className="input"
                    onChange={handleOtionChange}/>
                   
                  <label for="berries_1" className="berries_2">
                    Regular number
                  </label>
                    </> 
                     )}
                     </div>



                    <div class="form__group">
                      <input
                        value="option2"
                        className="input"
                        checked={selectedOption === 'option2'}
                        onClick={handleShowPremium}
                        name="groupt12"
                        id="radio2"
                        type="radio"
                        onChange={handleOtionChange}
                      />
                      <label for="berries_2" className="berriespie">
                        Premium
                      </label>
                    </div>
                  </fieldset>

                  {/*                  
                  <div className='optionsBtn'>
                    <div className='radio-itemFirst' >
                      <input onClick={HandleShowRegular} name='radio' id='radio1' checked type='radio' />
                      <label for='radio1' className='radioShowLabel'>
                     
                      </label>
                    </div>
                    <div
                      className='radio-itemFirst raio-item2'

                    >
                      <input onClick={handleShowPremium} name='radio' id='radio2' type='radio' />
                      <label for='radio2' className='radioShowLabel'>
                        Premium or nothing! Money is nothing
                      </label>
                    </div>
                  </div> */}
                </div>

                <p className="reserVtxtinteret2">
                  If you are interested in numbers for IoT, CUG, APN for company use,{' '}
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: 'yellowgreen',
                      cursor: 'pointer',
                    }}
                    onClick={navigateCorporateSolution}
                  >
                    {' '}
                    click here
                  </span>
                </p>
              </div>
            </div>
            {/* <div className='heroImage'>  </div> */}
          </div>
        </div>

        <div className="mainContainerDiv " id="premiumSection">
          <div className="secondDivLayer"></div>
          <div className="thirdLayerDiv">
            {showRegular && (
              <div className=" container-myForm">
                <div className="quiz-window-title2">
                  Pick up to 4 numbers from the options below. The numbers will be reserved for you up to 30 days after
                  our launch.{' '}
                </div>
                <div className="checkbox-wrapper-24">
                  <div className="checkBoxDiv">
                    <input type="checkbox" id="check-24" name="check" value={checkedMoreNumber} onClick={onchangeMoreNumbers} />
                    <label for="check-24" className="mshiftb">
                      Check here, if you need more than one number
                    </label>
                  </div>
                  <div onClick={reshuffle} className="reshulfleBtn">
                    <button className="guiz-awards-but-back">
                      <i className="vtBackI">
                        {' '}
                        <MdOutlineChangeCircle size={25} className="vtBackIcon-icon2" />
                      </i>{' '}
                      Show different Sets
                    </button>
                  </div>
                </div>
                <div className="provides">
                  {totalCount === 4 && (
                    <div>
                      {' '}
                      <Alert variant="success">
                        <span>You can only pick maximum of four numbers</span>
                      </Alert>{' '}
                    </div>
                  )}
                </div>
                {/* </div> */}
                <div className="quiz-window-body">
                  <table className="tableReserv">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Pick</th>
                        <th scope="col">Number</th>
                        <th scope="col">Price</th>
                        {/* <th scope='col'>Premium</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {regularDefault &&
                        regularNumbers.slice(0, 6).map(num => (
                          <tr>
                            <th scope="row">
                              {checkIds?.includes(num.serializedId) ? (
                                <button
                                  className="button-70Def"
                                  role="button"
                                  onChange={onchangeInput}
                                  value={num.serializedId}
                                  name="check"
                                  onClick={() => dispatch(remove(num.serializedId))}
                                >
                                  Picked
                                </button>
                              ) : (
                                <button
                                  className="button-70"
                                  role="button"
                                  onChange={onchangeInput}
                                  value={num.serializedId}
                                  name="check"
                                  onClick={() => handleAddToCart(num)}
                                  disabled={totalCount >= 4 || totalCount === 1 && checkedMoreNumber === false}
                                >
                                  Pick
                                </button>
                              )}
                            </th>
                            <td className="numDisplayBold">{num.serialNumber}</td>
                            <td>
                              {symbol}
                              {num.price}
                            </td>
                            {/* <td> {num.Premium}</td> */}
                          </tr>
                        ))}

                      {!regularDefault &&
                        displayedItems.map(num => (
                          <tr>
                            <th scope="row">
                              {checkIds?.includes(num.serializedId) ? (
                                <button
                                  className="button-70Def"
                                  role="button"
                                  onChange={onchangeInput}
                                  value={num.serializedId}
                                  name="check"
                                  onClick={() => dispatch(remove(num.serializedId))}
                                >
                                  Picked
                                </button>
                              ) : (
                                <button
                                  className="button-70"
                                  role="button"
                                  onChange={onchangeInput}
                                  value={num.serializedId}
                                  name="check"
                                  onClick={() => handleAddToCart(num)}
                                  disabled={totalCount >= 4 || totalCount === 1 && checkedMoreNumber === false}
                                >
                                  Pick
                                </button>
                              )}
                            </th>
                            <td className="numDisplayBold">{num.serialNumber}</td>
                            <td>
                              {' '}
                              {symbol}
                              {num.price}
                            </td>
                            {/* <td> {num.Premium}</td> */}
                          </tr>
                        ))}
                    </tbody>
                  </table>

                 
                </div>

                {progress === true && (
                  <table>
                    <tbody>
                      <tr>
                        <td className="td-1">
                          <span></span>
                        </td>
                        <td className="td-2">
                          <span></span>
                        </td>
                        <td className="td-3">
                          <span></span>
                        </td>
                        <td className="td-4"></td>
                        <td className="td-5">
                          <span></span>
                        </td>
                      </tr>
                      <tr>
                        <td className="td-1">
                          <span></span>
                        </td>
                        <td className="td-2">
                          <span></span>
                        </td>
                        <td className="td-3">
                          <span></span>
                        </td>
                        <td className="td-4"></td>
                        <td className="td-5">
                          <span></span>
                        </td>
                      </tr>
                      <tr>
                        <td className="td-1">
                          <span></span>
                        </td>
                        <td className="td-2">
                          <span></span>
                        </td>
                        <td className="td-3">
                          <span></span>
                        </td>
                        <td className="td-4"></td>
                        <td className="td-5">
                          <span></span>
                        </td>
                      </tr>
                      <tr>
                        <td className="td-1">
                          <span></span>
                        </td>
                        <td className="td-2">
                          <span></span>
                        </td>
                        <td className="td-3">
                          <span></span>
                        </td>
                        <td className="td-4"></td>
                        <td className="td-5">
                          <span></span>
                        </td>
                      </tr>
                      <tr>
                        <td className="td-1">
                          <span></span>
                        </td>
                        <td className="td-2">
                          <span></span>
                        </td>
                        <td className="td-3">
                          <span></span>
                        </td>
                        <td className="td-4"></td>
                        <td className="td-5">
                          <span></span>
                        </td>
                      </tr>
                      <tr>
                        <td className="td-1">
                          <span></span>
                        </td>
                        <td className="td-2">
                          <span></span>
                        </td>
                        <td className="td-3">
                          <span></span>
                        </td>
                        <td className="td-4"></td>
                        <td className="td-5">
                          <span></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}

                <div className="quiz-window-bo">
                  <div className="guiz-awards-buttons">
                    <div className="selectedy">
                      Selected: <span className="selectedAmount"> {totalCount}</span>{' '}
                    </div>

                    <div className="justifySubmit">
                      <button className="guiz-awards-but-back2" onClick={handleShow}>
                        <i className="fa fa-angle-left"></i>Click to Add
                      </button>{' '}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {showPremium && (
              <div className=" container-myForm">
                <div className="quiz-window-header">
                  <div className="quiz-window-title2">
                    Pick up to 4 numbers from the options below. The numbers will be yours forever once you pay the
                    premium.{' '}
                  </div>

                </div>

                <div className="checkbox-wrapper-24 ">

                  <div className="selectChoice">
                    <label> What’s your style?</label>
                    <select className="secTech2" onChange={handleFilterChange}>
                      <option>Choose...</option>
                      {mainNumbers &&
                        mainNumbers
                          .filter(product => product.productName.includes('Premium'))
                          .map((product, index) => <option value={product.productId}> {product.productName} </option>)}

                      {/* <option value='11'>Something Special</option>
                      <option value='22'>Lots of 0s</option>
                      <option value='33'>3somes</option>
                      <option value='44'>Lucky 7s</option> */}

                      {/* <option value='premium2'>Premium N10,000</option>
                      <option value='premium3'>Premium N15,000</option>
                      <option value='premium4'>Premium N25,000</option>
                      <option value='premium5'>Premium N45,000</option>
                      */}
                    </select>
                  </div>

                  <div onClick={() => reshufflePremium()} className="reshulfleBtn">
                    <button className="guiz-awards-but-back">
                      <i className="vtBa">
                        {' '}
                        <MdOutlineChangeCircle size={25} className="vtBackIcon-icon2" />
                      </i>{' '}
                      Show different Sets
                    </button>
                  </div>
                </div>
                <div className="provides">
                  {totalCount === 4 && (
                    <div>
                      {' '}
                      <Alert variant="success">
                        <span>You can only pick maximum of four numbers</span>
                      </Alert>{' '}
                    </div>
                  )}
                </div>

                <div className="quiz-window-body">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Pick</th>
                        <th scope="col">Number</th>
                        <th scope="col">Price</th>
                      </tr>
                    </thead>

                    <tbody>
                      {/* {premiumDefault && 
                        PremiumNumbers && PremiumNumbers.slice(0, 6).map((num) => (
                          <tr>
                            <th scope='row'>
                              {checkIds?.includes(num.serializedId) ? (
                                <button
                                  class='button-70Def'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.serializedId}
                                  name='check'
                                  onClick={() =>
                                    dispatch(remove(num.serializedId))
                                  }>
                                  Picked
                                </button>
                              ) : (
                                <button
                                  className='button-70'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.serializedId}
                                  name='check'
                                  onClick={() => handleAddToCart(num)}
                                  disabled={totalCount >= 4}
                                >
                                  Pick
                                </button>
                              )}
                            </th>
                            <td className='numDisplayBold'>{num.serialNumber}</td>
                            <td> {symbol}{num.price}</td>
                            

                          </tr>
                          )
                          )
                          }
                       
                      {

                         premiumDefault &&
                         displayedItemsPremium && displayedItemsPremium.slice(0, 6).map((num) => (
                          <tr>
                            <th scope='row'>
                              {checkIds?.includes(num.serializedId) ? (
                                <button
                                  class='button-70Def'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.serializedId}
                                  name='check'
                                  onClick={() =>
                                    dispatch(remove(num.serializedId))
                                  }

                                >
                                  Picked
                                </button>
                              ) : (
                                <button
                                  className='button-70'
                                  role='button'
                                  onChange={onchangeInput}
                                  value={num.serializedId}
                                  name='check'
                                  onClick={() => handleAddToCart(num)}
                                  disabled={totalCount >= 4}
                                >
                                  Pick
                                </button>
                              )}
                            </th>
                            <td className='numDisplayBold'>{num.serialNumber}</td>
                            <td> {symbol}{num.price}</td>
                            

                          </tr>
                        )

                        )
                      }
                      
                    
                        */}

                      {/* //////////////////////////////////////////////////////////////////// */}
                      {premiumDefaultNumbers &&
                        showDefaultPremiumNum.slice(0, 6).map(num => (
                          <tr>
                            <th scope="row">
                              {checkIds?.includes(num.serializedId) ? (
                                <button
                                  class="button-70Def"
                                  role="button"
                                  onChange={onchangeInput}
                                  value={num.serializedId}
                                  name="check"
                                  onClick={() => dispatch(remove(num.serializedId))}
                                >
                                  Picked
                                </button>
                              ) : (
                                <button
                                  className="button-70"
                                  role="button"
                                  onChange={onchangeInput}
                                  value={num.serializedId}
                                  name="check"
                                  onClick={() => handleAddToCart(num)}
                                  disabled={totalCount >= 4}
                                >
                                  Pick
                                </button>
                              )}
                            </th>
                            <td className="numDisplayBold">{num.serialNumber}</td>
                            <td>
                              {' '}
                              {symbol}
                              {num.price}
                            </td>
                          </tr>
                        ))}

                      {premiumDefault &&
                        premiumFilteredData.slice(0, 6).map(num => (
                          <tr>
                            <th scope="row">
                              {checkIds?.includes(num.serializedId) ? (
                                <button
                                  class="button-70Def"
                                  role="button"
                                  onChange={onchangeInput}
                                  value={num.serializedId}
                                  name="check"
                                  onClick={() => dispatch(remove(num.serializedId))}
                                >
                                  Picked
                                </button>
                              ) : (
                                <button
                                  className="button-70"
                                  role="button"
                                  onChange={onchangeInput}
                                  value={num.serializedId}
                                  name="check"
                                  onClick={() => handleAddToCart(num)}
                                  disabled={totalCount >= 4}
                                >
                                  Pick
                                </button>
                              )}
                            </th>
                            <td className="numDisplayBold">{num.serialNumber}</td>
                            <td>
                              {' '}
                              {symbol}
                              {num.price}
                            </td>
                          </tr>
                        ))}

                      {!premiumDefault &&
                        displayedItemsPremium &&
                        displayedItemsPremium.slice(0, 6).map(num => (
                          <tr>
                            <th scope="row">
                              {checkIds?.includes(num.serializedId) ? (
                                <button
                                  class="button-70Def"
                                  role="button"
                                  onChange={onchangeInput}
                                  value={num.serializedId}
                                  name="check"
                                  onClick={() => dispatch(remove(num.serializedId))}
                                >
                                  Picked
                                </button>
                              ) : (
                                <button
                                  className="button-70"
                                  role="button"
                                  onChange={onchangeInput}
                                  value={num.serializedId}
                                  name="check"
                                  onClick={() => handleAddToCart(num)}
                                  disabled={totalCount >= 4}
                                >
                                  Pick
                                </button>

                              )}
                            </th>
                            <td className="numDisplayBold">{num.serialNumber}</td>
                            <td>
                              {' '}
                              {symbol}
                              {num.price}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {premiumFilteredData &&
                    premiumFilteredData.length === 0 &&
                    showDefaultPremiumNum &&
                    showDefaultPremiumNum.length === 0 &&
                    !allNumberPending && (
                      <div className="datSEt-text p-2" style={{ textAlign: 'center', justifyContent: 'center' }}>
                        {' '}
                        The selected Category is not available for the moment{' '}
                      </div>
                    )}

                  {allNumberPending && (
                    <table className="table_skeskeleton">
                      <tbody>
                        <tr>
                          <td className="td-1">
                            <span></span>
                          </td>
                          <td className="td-2">
                            <span></span>
                          </td>
                          <td className="td-3">
                            <span></span>
                          </td>
                          <td className="td-4"></td>
                          <td className="td-5">
                            <span></span>
                          </td>
                        </tr>
                        <tr>
                          <td className="td-1">
                            <span></span>
                          </td>
                          <td className="td-2">
                            <span></span>
                          </td>
                          <td className="td-3">
                            <span></span>
                          </td>
                          <td className="td-4"></td>
                          <td className="td-5">
                            <span></span>
                          </td>
                        </tr>
                        <tr>
                          <td className="td-1">
                            <span></span>
                          </td>
                          <td className="td-2">
                            <span></span>
                          </td>
                          <td className="td-3">
                            <span></span>
                          </td>
                          <td className="td-4"></td>
                          <td className="td-5">
                            <span></span>
                          </td>
                        </tr>
                        <tr>
                          <td className="td-1">
                            <span></span>
                          </td>
                          <td className="td-2">
                            <span></span>
                          </td>
                          <td className="td-3">
                            <span></span>
                          </td>
                          <td className="td-4"></td>
                          <td className="td-5">
                            <span></span>
                          </td>
                        </tr>
                        <tr>
                          <td className="td-1">
                            <span></span>
                          </td>
                          <td className="td-2">
                            <span></span>
                          </td>
                          <td className="td-3">
                            <span></span>
                          </td>
                          <td className="td-4"></td>
                          <td className="td-5">
                            <span></span>
                          </td>
                        </tr>
                        <tr>
                          <td className="td-1">
                            <span></span>
                          </td>
                          <td className="td-2">
                            <span></span>
                          </td>
                          <td className="td-3">
                            <span></span>
                          </td>
                          <td className="td-4"></td>
                          <td className="td-5">
                            <span></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}

                  <div className="quiz-window-body-2">
                    <div className="guiz-awards-buttons">
                      <div className="selectedy">
                        Selected: <span className="selectedAmount"> {totalCount}</span>{' '}
                      </div>

                      <div className="justifySubmit">
                        <button className="guiz-awards-but-back2" onClick={handleShow}>
                          <i className="fa fa-angle-left"></i>Check Out
                        </button>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Modal show={show} onHide={handleClose} backdrop="static">
              <Modal.Header>
                <Modal.Title>Vitel Number(s) Reservation</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ fontSize: '18px' }}>
                You have not selected new numbers. Select Cancel to return to number selection page or{' '}
                <span className="hereModal" onClick={navigateReservedNumber}>
                  {' '}
                  click here
                </span>{' '}
                to review your numbers already selected
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* <div className='moveFooterRes'>
        <Footer />
      </div> */}
    </>
  );
};

export default ReserveANumber;
