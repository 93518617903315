import React, { useState, useEffect } from 'react';
import './SubscriberUtility.css';
import { orderLocationId, orderMenuId, vitelWirelessAxios } from '../../utility/axios';
import noItemFound from '../../images/noproduct.png';
import { useNavigate, Link } from 'react-router-dom';
import secureme from '../../images/secureme.jpg';
import ogaApp from '../../images/ogaapp.jpg';
import ogaBoiz from '../../images/ogaboiz.jpg';

const SubscriberUtilities = () => {
  const [VitelVASProduct, setVitelVasProduct] = useState([]);
  const [VitelVASProductCategory, setVitelVASProductCategory] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState("");
  const [checkEmptyArr, setCheckEmptyArr] = useState(false);
  const navigation = useNavigate();

  const activateVAS = () => {
    navigation('/product-details');
  };

  const handleNavigateDetails = devices => {
    //  navigation(`/product-details/id=${devices.productId}/name=${devices.productName}`, { state: { product: devices } });
  };

  const handleCategoryChange = async e => {
    const selectedCategory = e.target.value;
    const productDetails = await vitelWirelessAxios.get(`/generals/getAllPublicImages?sourceType=web`);

    if (selectedCategory == 'all') {
      const filteredProductServOnly = productDetails.data.filter(
        service => service.categoryName === 'Premium/Value Added Services'
      );
      const subcategoryArray = filteredProductServOnly.flatMap(item => item.subCategoryImages);
      setCheckEmptyArr(false);
      setVitelVasProduct(subcategoryArray);
    } else {
      const filteredServices = productDetails.data.filter(service => service.productSubCategory == selectedCategory);

      if (filteredServices.length === 0) {
        setCheckEmptyArr(true);
      } else {
        setCheckEmptyArr(false);
      }
      setVitelVasProduct(filteredServices[0]?.subCategoryImages);
    }
  };

  useEffect(() => {
    const getVitelVASProduct = async () => {
      const vasProductDetails = await vitelWirelessAxios.get(
        `/products/menus/${orderMenuId}?locationId=${orderLocationId}?sourceType=web`
      );
      const vasProductCategory = await vitelWirelessAxios.get(
        `/products/menus/${orderMenuId}?locationId=${orderLocationId}`
      );

      const vasProductDetailsProduct = vasProductDetails?.data;

      const vasCategoryImages =
        vasProductDetailsProduct.products &&
        vasProductDetailsProduct.products
          .filter(product => product.productDetailImages && product.productDetailImages.length > 0)
          .flatMap(product => product.productDetailImages);

      const filteredProductServCategory = vasCategoryImages.filter(
        service => service.categoryName === 'Premium/Value Added Services'
      );

      const filteredProductServOnly = vasCategoryImages.filter(
        service => service.categoryName === 'Premium/Value Added Services'
      );

      const subcategoryArray = filteredProductServOnly.flatMap(item => item);

      console.log('subcategoryArray', subcategoryArray);

      if (filteredProductServOnly.length === 0) {
        setCheckEmptyArr(true);
      } else {
        setCheckEmptyArr(false);
      }
      // setVitelVASProductCategory(filteredProductServCategory[0].subCategories);
      setVitelVasProduct(subcategoryArray);
    };
    getVitelVASProduct();
  }, []);

  const encodedProductName = vasProduct => {
    return encodeURIComponent(vasProduct.productName);
  };

  return (
    <div className="parentAppListing">
      <div className="getStartedContainer">
        <div className="getStartedContainerItem">
          <span className="getSecured">
            Get Value Added Services <hr />
          </span>
          <div className="filterDiv">
            <label htmlFor="vasFilter">filter VAS</label>
            <select name="vasFilter" id="" onChange={handleCategoryChange}>
              <option value="" disabled>
                Select an option
              </option>
              <option value="all">All</option>
              {VitelVASProductCategory &&
                VitelVASProductCategory.map(category => (
                  <option key={category.subCategoryId} value={category.subCategoryId}>
                    {category.subCategoryName}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
      <div className="parentAppSampleDiv">
        <div className="appSampleDiv">
          {checkEmptyArr ? (
            <div className="secureMe2">
              <img src={noItemFound} alt="" />
            </div>
          ) : (
            VitelVASProduct &&
            VitelVASProduct?.map(vasProduct => (
              <Link
                style={{ textDecoration: 'none' }}
                to={`/product-details/${vasProduct.productId}/${encodedProductName(vasProduct)}`}
                state={{ product: vasProduct }}
                key={vasProduct.productId}
                // onClick={() => handleNavigateDetails(vasProduct)}
                className="secureMe"
              >
                <div className="vasType">Free</div>
                <div className="vasParentPriceDiv">
                  <div className="vasPrice">&#8358; {parseInt(vasProduct.pricePerItem).toLocaleString()}</div>
                </div>
                <div className="secureMe-header">
                  <img src={vasProduct.displayImage} alt="" />
                </div>
                <div className="appBody">
                  <p className="description">{vasProduct.shortDescription}</p>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriberUtilities;
