import { FaListUl, FaRegHeart, FaHeart, FaSearch } from 'react-icons/fa';
import './e-commerce.css';
import { GiShoppingCart } from 'react-icons/gi';
import img1 from '../../images/wear/camera.jpg';
import img2 from '../../images/wear/watch2.jpg';
import img3 from '../../images/wear/watch.jpg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useFectch from '../../utility/getFunction';
import SearchBar from './searchBar';
import { useDispatch, useSelector } from 'react-redux';
import { addToCartProduct } from '../../redux/productCounter';
import { orderLocationId, orderMenuId, vitelWirelessAxios } from '../../utility/axios';
import { Alert } from 'react-bootstrap';
// import { addToCartProduct } from "../../redux/counter";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IoIosReturnLeft } from 'react-icons/io';

const EcomCategories = () => {
  const location = useLocation();

  const { categories } = location.state || {}; // Safeguard if state is undefined

  const [allCategories, setAllCategories] = useState(true);
  const [subCatName, setSubCatName] = useState('');
  const [showDropDown, setShowDropDown] = useState(true);
  const [item, setItem] = useState('');
  const [favouritItemArr, setFavouritItemArr] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showCategoryFilter, setShowCategoryFilter] = useState('');
  const [showCat, setShowCat] = useState('');
  const subscribersData = JSON.parse(localStorage.getItem('subscriberInformation'));
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

  console.log('Test the categories', categories);

  const totalCount = useSelector(state => {
    return state.cartProduct.productItems.length;
  });

  const scrolltop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    scrolltop();
  }, []);

  const navigateShoppingCart = () => {
    navigate('/shopping-cart');
  };

  const handleAllSubCat = () => {
    setAllCategories(true);
  };

  const {
    data: productsItem,
    isPending: productsIsPending,
    error: productsError,
  } = useFectch(`/products/menus/${orderMenuId}?locationId=${orderLocationId}?sourceType=web`);

  const subCatogoryFilter =
    productsItem.products && productsItem.products.flatMap(products => products.productDetailImages);

  const filteredProducts = subCatogoryFilter && subCatogoryFilter.filter(product => product.categoryId === categories);

  const [showData, setShowData] = useState('');

  const navigate = useNavigate();

  const navigateDetailPage = () => {
    navigate('/product-details');
  };

  const navigateCart = pro => {
    // console.log("itemCart", pro);
    navigate('/shopping-cart');
  };
  console.log('itemCart', item);

  const dispatch = useDispatch();

  // const subCategoryImagesDisplay =  products
  // .filter(product => subCatName === product.productCategory)
  // .flatMap(product => product.subCategoryImages);

  const handleDataProduct = pro => {
    dispatch(addToCartProduct(pro));
    handleShow(true);
    setItem(pro);
  };

  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN',
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('');
  //

  const handleNavigateDetails = pro => {
    navigate('/product-details', { state: { product: pro } });
  };

  useEffect(() => {
    const fetchWishList = async () => {
      const getSubscriberWishList = await vitelWirelessAxios.get(
        `subscribers/getProductWishlists/${subscribersData?.customerId}?sourceType=web`
      );

      console.log('getSubscriberWishList', getSubscriberWishList.data);
      setFavouritItemArr(getSubscriberWishList.data); // Update state to trigger re-render
    };

    fetchWishList();
  }, [subscribersData?.customerId]);

  const handleAddFavourit = async (favItemDetails, newItem) => {
    const userInfor = {
      productId: favItemDetails.productId,
      customerId: subscribersData?.customerId,
      type: newItem,
      sourceType: 'web',
    };

    await vitelWirelessAxios.post('subscribers/createProductWishlist', userInfor);
    const getSubscriberWishList = await vitelWirelessAxios.get(
      `subscribers/getProductWishlists/${subscribersData?.customerId}`
    );

    setFavouritItemArr(getSubscriberWishList?.data); // Update state with the new wishlist
    console.log('Item added to favourites:', getSubscriberWishList?.data);
  };

  const navigateEcommerceNav = () => {
    navigate('/e-commerce');
  };
  const navigateHome = () => {
    navigate('/');
  };

  return (
    <>
      <div>
        <SearchBar />

        <div className="bredMove">
          <nav className="breadcrumbs">
            <a className="breadcrumbs__item" onClick={navigateHome}>
              Home
            </a>
            <a className="breadcrumbs__item  " onClick={navigateEcommerceNav}>
              All Products
            </a>
            <a className="breadcrumbs__item  is-active">Categories</a>

            {/* <a className="breadcrumbs__item ">Checkout</a>  */}
          </nav>
        </div>

        {productsIsPending && (
          <div className="grid-container">
            <div id="card">
              <div className="card-image">
                <div className="block pulsate"></div>
                <svg className="fpo " style={{ width: '84px', height: '63px' }}>
                  <title>Shape</title>
                  <desc>Created with Sketch.</desc>
                  <defs></defs>
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                    transform="translate(-964.000000, -1012.000000)"
                    fill-opacity="0.06"
                  >
                    <g id="16---Workpaper-Loading-Copy" transform="translate(836.000000, 909.000000)" fill="#000000">
                      <g id="Icons-/-Special-/-RTE-/-Image" transform="translate(100.000000, 67.000000)">
                        <g id="Icons-/-RTE-/-ImageSpecial">
                          <path
                            d="M108.368088,36.5625 L30.8485565,36.5625 C29.319526,36.5625 28.0800018,37.8216991 28.0800018,39.375 L28.0800018,95.625 C28.0800018,97.1783009 29.319526,98.4375 30.8485565,98.4375 L108.368088,98.4375 C109.897118,98.4375 111.136642,97.1783009 111.136642,95.625 L111.136642,39.375 C111.136642,37.8216991 109.897118,36.5625 108.368088,36.5625 L108.368088,36.5625 Z M105.599533,42.1875 L105.599533,81.225 L96.7678436,68.68125 C96.2965986,68.0076728 95.5575991,67.5787153 94.747102,67.5082962 C93.936605,67.4378771 93.1366348,67.7331229 92.5596405,68.315625 L82.0668182,79.003125 L59.1154999,55.6875 C58.0356599,54.5970274 56.2916778,54.5970274 55.2118378,55.6875 L33.6171112,77.596875 L33.6171112,42.1875 L105.599533,42.1875 L105.599533,42.1875 Z M33.6171112,92.8125 L33.6171112,85.528125 L57.149826,61.621875 L80.1011444,84.9375 C81.1809844,86.0279726 82.9249665,86.0279726 84.0048065,84.9375 L94.1654022,74.64375 L105.599533,90.9 L105.599533,92.8125 L33.6171112,92.8125 L33.6171112,92.8125 Z M77.9139862,56.25 C77.9139862,53.1433983 80.3930345,50.625 83.4510956,50.625 C86.5091566,50.625 88.988205,53.1433983 88.988205,56.25 C88.988205,59.3566017 86.5091566,61.875 83.4510956,61.875 C80.3930345,61.875 77.9139862,59.3566017 77.9139862,56.25 L77.9139862,56.25 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="card-content">
                <div className="block2 pulsate"></div>
                <div className="block3 pulsate"></div>
                <div className="circle pulsate"></div>
                <div></div>
              </div>
            </div>
            <div id="card">
              <div className="card-image">
                <div className="block pulsate"></div>
                <svg className="fpo " style={{ width: '84px', height: '63px' }}>
                  <title>Shape</title>
                  <desc>Created with Sketch.</desc>
                  <defs></defs>
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                    transform="translate(-964.000000, -1012.000000)"
                    fill-opacity="0.06"
                  >
                    <g id="16---Workpaper-Loading-Copy" transform="translate(836.000000, 909.000000)" fill="#000000">
                      <g id="Icons-/-Special-/-RTE-/-Image" transform="translate(100.000000, 67.000000)">
                        <g id="Icons-/-RTE-/-ImageSpecial">
                          <path
                            d="M108.368088,36.5625 L30.8485565,36.5625 C29.319526,36.5625 28.0800018,37.8216991 28.0800018,39.375 L28.0800018,95.625 C28.0800018,97.1783009 29.319526,98.4375 30.8485565,98.4375 L108.368088,98.4375 C109.897118,98.4375 111.136642,97.1783009 111.136642,95.625 L111.136642,39.375 C111.136642,37.8216991 109.897118,36.5625 108.368088,36.5625 L108.368088,36.5625 Z M105.599533,42.1875 L105.599533,81.225 L96.7678436,68.68125 C96.2965986,68.0076728 95.5575991,67.5787153 94.747102,67.5082962 C93.936605,67.4378771 93.1366348,67.7331229 92.5596405,68.315625 L82.0668182,79.003125 L59.1154999,55.6875 C58.0356599,54.5970274 56.2916778,54.5970274 55.2118378,55.6875 L33.6171112,77.596875 L33.6171112,42.1875 L105.599533,42.1875 L105.599533,42.1875 Z M33.6171112,92.8125 L33.6171112,85.528125 L57.149826,61.621875 L80.1011444,84.9375 C81.1809844,86.0279726 82.9249665,86.0279726 84.0048065,84.9375 L94.1654022,74.64375 L105.599533,90.9 L105.599533,92.8125 L33.6171112,92.8125 L33.6171112,92.8125 Z M77.9139862,56.25 C77.9139862,53.1433983 80.3930345,50.625 83.4510956,50.625 C86.5091566,50.625 88.988205,53.1433983 88.988205,56.25 C88.988205,59.3566017 86.5091566,61.875 83.4510956,61.875 C80.3930345,61.875 77.9139862,59.3566017 77.9139862,56.25 L77.9139862,56.25 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="card-content">
                <div className="block2 pulsate"></div>
                <div className="block3 pulsate"></div>
                <div className="circle pulsate"></div>
                <div></div>
              </div>
            </div>
            <div id="card">
              <div className="card-image">
                <div className="block pulsate"></div>
                <svg className="fpo " style={{ width: '84px', height: '63px' }}>
                  <title>Shape</title>
                  <desc>Created with Sketch.</desc>
                  <defs></defs>
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                    transform="translate(-964.000000, -1012.000000)"
                    fill-opacity="0.06"
                  >
                    <g id="16---Workpaper-Loading-Copy" transform="translate(836.000000, 909.000000)" fill="#000000">
                      <g id="Icons-/-Special-/-RTE-/-Image" transform="translate(100.000000, 67.000000)">
                        <g id="Icons-/-RTE-/-ImageSpecial">
                          <path
                            d="M108.368088,36.5625 L30.8485565,36.5625 C29.319526,36.5625 28.0800018,37.8216991 28.0800018,39.375 L28.0800018,95.625 C28.0800018,97.1783009 29.319526,98.4375 30.8485565,98.4375 L108.368088,98.4375 C109.897118,98.4375 111.136642,97.1783009 111.136642,95.625 L111.136642,39.375 C111.136642,37.8216991 109.897118,36.5625 108.368088,36.5625 L108.368088,36.5625 Z M105.599533,42.1875 L105.599533,81.225 L96.7678436,68.68125 C96.2965986,68.0076728 95.5575991,67.5787153 94.747102,67.5082962 C93.936605,67.4378771 93.1366348,67.7331229 92.5596405,68.315625 L82.0668182,79.003125 L59.1154999,55.6875 C58.0356599,54.5970274 56.2916778,54.5970274 55.2118378,55.6875 L33.6171112,77.596875 L33.6171112,42.1875 L105.599533,42.1875 L105.599533,42.1875 Z M33.6171112,92.8125 L33.6171112,85.528125 L57.149826,61.621875 L80.1011444,84.9375 C81.1809844,86.0279726 82.9249665,86.0279726 84.0048065,84.9375 L94.1654022,74.64375 L105.599533,90.9 L105.599533,92.8125 L33.6171112,92.8125 L33.6171112,92.8125 Z M77.9139862,56.25 C77.9139862,53.1433983 80.3930345,50.625 83.4510956,50.625 C86.5091566,50.625 88.988205,53.1433983 88.988205,56.25 C88.988205,59.3566017 86.5091566,61.875 83.4510956,61.875 C80.3930345,61.875 77.9139862,59.3566017 77.9139862,56.25 L77.9139862,56.25 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="card-content">
                <div className="block2 pulsate"></div>
                <div className="block3 pulsate"></div>
                <div className="circle pulsate"></div>
                <div></div>
              </div>
            </div>
            <div id="card">
              <div className="card-image">
                <div className="block pulsate"></div>
                <svg className="fpo " style={{ width: '84px', height: '63px' }}>
                  <title>Shape</title>
                  <desc>Created with Sketch.</desc>
                  <defs></defs>
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                    transform="translate(-964.000000, -1012.000000)"
                    fill-opacity="0.06"
                  >
                    <g id="16---Workpaper-Loading-Copy" transform="translate(836.000000, 909.000000)" fill="#000000">
                      <g id="Icons-/-Special-/-RTE-/-Image" transform="translate(100.000000, 67.000000)">
                        <g id="Icons-/-RTE-/-ImageSpecial">
                          <path
                            d="M108.368088,36.5625 L30.8485565,36.5625 C29.319526,36.5625 28.0800018,37.8216991 28.0800018,39.375 L28.0800018,95.625 C28.0800018,97.1783009 29.319526,98.4375 30.8485565,98.4375 L108.368088,98.4375 C109.897118,98.4375 111.136642,97.1783009 111.136642,95.625 L111.136642,39.375 C111.136642,37.8216991 109.897118,36.5625 108.368088,36.5625 L108.368088,36.5625 Z M105.599533,42.1875 L105.599533,81.225 L96.7678436,68.68125 C96.2965986,68.0076728 95.5575991,67.5787153 94.747102,67.5082962 C93.936605,67.4378771 93.1366348,67.7331229 92.5596405,68.315625 L82.0668182,79.003125 L59.1154999,55.6875 C58.0356599,54.5970274 56.2916778,54.5970274 55.2118378,55.6875 L33.6171112,77.596875 L33.6171112,42.1875 L105.599533,42.1875 L105.599533,42.1875 Z M33.6171112,92.8125 L33.6171112,85.528125 L57.149826,61.621875 L80.1011444,84.9375 C81.1809844,86.0279726 82.9249665,86.0279726 84.0048065,84.9375 L94.1654022,74.64375 L105.599533,90.9 L105.599533,92.8125 L33.6171112,92.8125 L33.6171112,92.8125 Z M77.9139862,56.25 C77.9139862,53.1433983 80.3930345,50.625 83.4510956,50.625 C86.5091566,50.625 88.988205,53.1433983 88.988205,56.25 C88.988205,59.3566017 86.5091566,61.875 83.4510956,61.875 C80.3930345,61.875 77.9139862,59.3566017 77.9139862,56.25 L77.9139862,56.25 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="card-content">
                <div className="block2 pulsate"></div>
                <div className="block3 pulsate"></div>
                <div className="circle pulsate"></div>
                <div></div>
              </div>
            </div>
            <div id="card">
              <div className="card-image">
                <div className="block pulsate"></div>
                <svg className="fpo " style={{ width: '84px', height: '63px' }}>
                  <title>Shape</title>
                  <desc>Created with Sketch.</desc>
                  <defs></defs>
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                    transform="translate(-964.000000, -1012.000000)"
                    fill-opacity="0.06"
                  >
                    <g id="16---Workpaper-Loading-Copy" transform="translate(836.000000, 909.000000)" fill="#000000">
                      <g id="Icons-/-Special-/-RTE-/-Image" transform="translate(100.000000, 67.000000)">
                        <g id="Icons-/-RTE-/-ImageSpecial">
                          <path
                            d="M108.368088,36.5625 L30.8485565,36.5625 C29.319526,36.5625 28.0800018,37.8216991 28.0800018,39.375 L28.0800018,95.625 C28.0800018,97.1783009 29.319526,98.4375 30.8485565,98.4375 L108.368088,98.4375 C109.897118,98.4375 111.136642,97.1783009 111.136642,95.625 L111.136642,39.375 C111.136642,37.8216991 109.897118,36.5625 108.368088,36.5625 L108.368088,36.5625 Z M105.599533,42.1875 L105.599533,81.225 L96.7678436,68.68125 C96.2965986,68.0076728 95.5575991,67.5787153 94.747102,67.5082962 C93.936605,67.4378771 93.1366348,67.7331229 92.5596405,68.315625 L82.0668182,79.003125 L59.1154999,55.6875 C58.0356599,54.5970274 56.2916778,54.5970274 55.2118378,55.6875 L33.6171112,77.596875 L33.6171112,42.1875 L105.599533,42.1875 L105.599533,42.1875 Z M33.6171112,92.8125 L33.6171112,85.528125 L57.149826,61.621875 L80.1011444,84.9375 C81.1809844,86.0279726 82.9249665,86.0279726 84.0048065,84.9375 L94.1654022,74.64375 L105.599533,90.9 L105.599533,92.8125 L33.6171112,92.8125 L33.6171112,92.8125 Z M77.9139862,56.25 C77.9139862,53.1433983 80.3930345,50.625 83.4510956,50.625 C86.5091566,50.625 88.988205,53.1433983 88.988205,56.25 C88.988205,59.3566017 86.5091566,61.875 83.4510956,61.875 C80.3930345,61.875 77.9139862,59.3566017 77.9139862,56.25 L77.9139862,56.25 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="card-content">
                <div className="block2 pulsate"></div>
                <div className="block3 pulsate"></div>
                <div className="circle pulsate"></div>
                <div></div>
              </div>
            </div>
            <div id="card">
              <div className="card-image">
                <div className="block pulsate"></div>
                <svg className="fpo " style={{ width: '84px', height: '63px' }}>
                  <title>Shape</title>
                  <desc>Created with Sketch.</desc>
                  <defs></defs>
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                    transform="translate(-964.000000, -1012.000000)"
                    fill-opacity="0.06"
                  >
                    <g id="16---Workpaper-Loading-Copy" transform="translate(836.000000, 909.000000)" fill="#000000">
                      <g id="Icons-/-Special-/-RTE-/-Image" transform="translate(100.000000, 67.000000)">
                        <g id="Icons-/-RTE-/-ImageSpecial">
                          <path
                            d="M108.368088,36.5625 L30.8485565,36.5625 C29.319526,36.5625 28.0800018,37.8216991 28.0800018,39.375 L28.0800018,95.625 C28.0800018,97.1783009 29.319526,98.4375 30.8485565,98.4375 L108.368088,98.4375 C109.897118,98.4375 111.136642,97.1783009 111.136642,95.625 L111.136642,39.375 C111.136642,37.8216991 109.897118,36.5625 108.368088,36.5625 L108.368088,36.5625 Z M105.599533,42.1875 L105.599533,81.225 L96.7678436,68.68125 C96.2965986,68.0076728 95.5575991,67.5787153 94.747102,67.5082962 C93.936605,67.4378771 93.1366348,67.7331229 92.5596405,68.315625 L82.0668182,79.003125 L59.1154999,55.6875 C58.0356599,54.5970274 56.2916778,54.5970274 55.2118378,55.6875 L33.6171112,77.596875 L33.6171112,42.1875 L105.599533,42.1875 L105.599533,42.1875 Z M33.6171112,92.8125 L33.6171112,85.528125 L57.149826,61.621875 L80.1011444,84.9375 C81.1809844,86.0279726 82.9249665,86.0279726 84.0048065,84.9375 L94.1654022,74.64375 L105.599533,90.9 L105.599533,92.8125 L33.6171112,92.8125 L33.6171112,92.8125 Z M77.9139862,56.25 C77.9139862,53.1433983 80.3930345,50.625 83.4510956,50.625 C86.5091566,50.625 88.988205,53.1433983 88.988205,56.25 C88.988205,59.3566017 86.5091566,61.875 83.4510956,61.875 C80.3930345,61.875 77.9139862,59.3566017 77.9139862,56.25 L77.9139862,56.25 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="card-content">
                <div className="block2 pulsate"></div>
                <div className="block3 pulsate"></div>
                <div className="circle pulsate"></div>
                <div></div>
              </div>
            </div>
          </div>
        )}
        <div className="grid-container">
          {filteredProducts &&
            filteredProducts.map(pro => (
              <div className="product-card grid-item">
                {/* <div className="badge">Hot</div> */}
                <div className="product-tumb" onClick={() => handleNavigateDetails(pro)}>
                  <img className="img-product-tumb" src={pro?.displayImage} alt="" />
                </div>
                <div className="product-details">
                  <span className="product-catagory">{pro?.subCategoryName} </span>
                  <h5>
                    <a>{pro?.productName.slice(0, 15)}</a>
                  </h5>
                  <p> {pro?.shortDescription.slice(0, 50)} </p>
                  <div className="product-bottom-details">
                    <div className="product-price">
                      {symbol}
                      {pro?.pricePerItem}{' '}
                    </div>
                    <div className="product-links">
                      <a class="bt_addTocart" onClick={() => handleDataProduct(pro)}>
                        <i>
                          {' '}
                          add to cart <GiShoppingCart />{' '}
                        </i>{' '}
                      </a>
                      {isLoggedIn ? (
                        favouritItemArr.some(item => item.productId === pro.productId) ? (
                          <a onClick={() => handleAddFavourit(pro, 'exist')}>
                            <i>
                              <FaHeart />
                            </i>
                          </a>
                        ) : (
                          <a onClick={() => handleAddFavourit(pro, 'new')}>
                            <i>
                              <FaRegHeart />
                            </i>
                          </a>
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default EcomCategories;
