import axios from 'axios';

const baseURLVitelWireless = process.env.REACT_APP_BASE_URL;

const orderLocationId = process.env.REACT_APP_ORDER_LOCATION_ID;
const orderMenuId = process.env.REACT_APP_ORDER_MENU_ID;
const simLocationId = process.env.REACT_APP_SIM_LOCATION_ID

const openTableRoomId = process.env.REACT_APP_OPEN_TABLE_ID;
const registerId = process.env.REACT_APP_REGISTER_ID;
const serviceStaffId = process.env.REACT_APP_SERVICE_STAFF_ID;
 

const vitelWirelessAxios = axios.create({
  baseURL: baseURLVitelWireless
});





export { vitelWirelessAxios, orderLocationId , orderMenuId, openTableRoomId,registerId, serviceStaffId, simLocationId };
