import { useForm } from 'react-hook-form';
import { vitelWirelessAxios } from '../../utility/axios';
import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import { HiOutlineArrowUturnLeft } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';

let renderCount = 0;

const CorporateProductForm = () => {
  const [loading, setLoading] = useState(false);
  const [succeesMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [sendData, setSendData] = useState('');
  const [showForm, setShowForm] = useState(true);

  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors, isDirty, isValid },
  } = useForm();
  renderCount++;

  const navigate = useNavigate();

  const handleSubmitData = async data => {
    setLoading(true);
    setShowForm(true);
    // console.log('data', data);
    setSendData(data);
    await vitelWirelessAxios
      .post('/partners/partnerBusinessRequest', data)
      .then(res => {
        // console.log('res', res);
        setLoading(false);
        setShowForm(false);
        // reset();
        scrolltop();
        setSuccessMessage(true);
      })
      .catch(err => {
        console.log('err', err);
        setLoading(false);
        setErrorMessage(true);
        setShowForm(true);
      });
  };

  const scrolltop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    scrolltop();
  }, []);

  const navigateHome = () => {
    navigate('/');
  };

  const navigateCorporatePage = () => {
    navigate('/corperate-products');
  };

  return (
    <>
      {showForm && (
        <form
          id="contact"
          action=""
          method="post"
          onSubmit={handleSubmit((data, event) => {
            // console.log('seedataNow', data);
            handleSubmitData(data);
          })}
        >
          <h1 style={{ fontWeight: 'bold', textAlign: 'center' }} className="setHeadProduct">
            Vitel Wireless Business Solutions Request
          </h1>
          <h4 style={{ color: '#2BBCF1' }}>
            <span style={{ color: 'red' }}>*</span> Indicates required question
          </h4>
          {succeesMessage && (
            <Alert className="checkALERt">
              <span>
                <i>
                  <IoCheckmarkDoneSharp />
                </i>{' '}
                Congratulations! Your Request Has Been Registered
              </span>
              <span className="leftDotset" onClick={navigateHome}>
                <i>
                  <HiOutlineArrowUturnLeft />
                </i>{' '}
                Go Home
              </span>{' '}
            </Alert>
          )}

          <div className="setDivFOrm">
            <fieldset>
              <label>
                Your Full Name <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                className="form-control"
                placeholder="Your name"
                type="text"
                {...register('fullName', {
                  required: 'Full name is required',
                  maxLength: {},
                })}
              />
              <span className="cum-error">{errors.fullName?.message}</span>
            </fieldset>

            <fieldset>
              <label>
                Your Phone Number <span style={{ color: 'red' }}>*</span>{' '}
              </label>
              <input
                className="form-control"
                placeholder="Phone Number"
                type="number"
                tabindex="2"
                {...register('phoneNumber', {
                  required: 'Phone number is required',
                  maxLength: {},
                })}
              />
              <span className="cum-error">{errors.phoneNumber?.message}</span>
            </fieldset>
          </div>
          <div className="setDivFOrm">
            <fieldset>
              <label>
                Your Active Email Address<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                className="form-control"
                placeholder="Email Address"
               
                
                {...register('emailAddress', {
                  required: 'Email address is required',
                  validate: (value) => value.includes('@' && '.') || "Email must contain '@' and '.",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
              />
              <span className="cum-error">{errors.emailAddress?.message}</span>
            </fieldset>
            <fieldset>
              <label>
                Registered Company Name<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                className="form-control"
                placeholder="Company Name"
                type="text"
                tabindex="4"
                {...register('companyName', {
                  required: 'Company name is required',
                  maxLength: {},
                })}
              />
              <span className="cum-error">{errors.companyName?.message}</span>
            </fieldset>
          </div>
          <div className="setDivFOrm">
            <fieldset>
              <label>
                RC Number<span style={{ color: 'red' }}>*</span>
              </label>
              <input
                className="form-control"
                placeholder="RC Number"
                type="text"
                tabindex="3"
                {...register('rcNumber', {
                  required: 'RC number is required',
                  maxLength: {},
                })}
              />
              <span className="cum-error">{errors.rcNumber?.message}</span>
            </fieldset>
            <fieldset>
              <label>
                Partner Type<span style={{ color: 'red' }}>*</span>
              </label>
              <select
                name="partnerType"
                id="partnerType"
                className="form-control"
                {...register('partnerType', {
                  required: 'Partner type is required',
                  maxLength: {},
                })}
              >
                <option value="">select partner</option>
                <option value="Double MyHustle Agent(DA)">Double MyHustle Agent(DA)</option>
                <option value="Executive Agent(EA)">Executive Agent(EA)</option>
                <option value="Power Agent(PA)">Power Agent(PA)</option>
              </select>

              {/* <input placeholder="RC Number" type="text" tabindex="3"  {...register('rcNumber', {
                                required: 'RC number is required',
                                maxLength: {},
                            })} /> */}
              <span className="cum-error">{errors.partnerType?.message}</span>
            </fieldset>
            <fieldset>
              <label>Estimate how many SIM cards you will need</label>
              <input
                className="form-control"
                placeholder="How many SIM cards you will need"
                type="text"
                tabindex="3"
                {...register('howManySim')}
              />
            </fieldset>
          </div>
          <h3>
            What are the SIM cards for? <span style={{ color: 'red' }}>*</span>{' '}
          </h3>

          <div className="teRadio_set">
            <div>
              <fieldset>
                <input type="checkbox" {...register('employeeTelcoNeeds')} />
                <label>Employees' telco needs</label>
              </fieldset>
              <fieldset>
                <input type="checkbox" {...register('employeePerformance')} />
                <label>Employees’ performance (Oga-App)</label>
              </fieldset>
              <fieldset>
                <input type="checkbox" {...register('locationBaseService')} />
                <label>Location-Based Service (LBS) API</label>
              </fieldset>
            </div>

            <div>
              <fieldset>
                <input type="checkbox" {...register('m2M')} />
                <label>M2M</label>
              </fieldset>
              <fieldset>
                <input type="checkbox" {...register('iot')} />
                <label>IoT</label>
              </fieldset>
              <fieldset>
                <label>Others</label>
                <input className="form-control" placeholder="Others" tabindex="4" {...register('others')} />
              </fieldset>
            </div>
          </div>

          <hr />
          <div className="setDivFOrm">
            <fieldset>
              <label>Estimate how much data do you need per SIM card</label>
              <input
                className="form-control"
                placeholder="MB, TB, GB"
                type="text"
                tabindex="1"
                {...register('dataPerSim')}
              />
            </fieldset>
            <fieldset>
              <label>Estimate how many minutes of talktime do you need per SIM card</label>
              <input
                className="form-control"
                placeholder="How many minutes talk per sim"
                type="text"
                tabindex="1"
                {...register('talkTimePerSim')}
              />
            </fieldset>
          </div>
          <div className="setDivFOrm">
            <fieldset>
              <label>Estimate how many SMS do you need per SIM card</label>
              <input
                className="form-control"
                placeholder="How many sms per sim"
                type="text"
                tabindex="1"
                {...register('smsPerSim')}
              />
            </fieldset>
            <fieldset>
              <label> By when will you need these SIM cards </label>
              <input
                className="form-control"
                placeholder="Now, in 3 months, later"
                type="text"
                tabindex="1"
                {...register('duration')}
              />
            </fieldset>
          </div>
          {errorMessage && <div style={{ color: 'red' }}>Something went wrong, please try again later!</div>}

          <div className="setProDUCtbtn">
            <div className="allBTNSUMit-product">
              {loading ? (
                <button type="submit" id="contact-submit">
                  <i className=""></i>
                  <span class="loaderButton"></span>{' '}
                </button>
              ) : (
                <button type="submit" id="contact-submit">
                  Submit
                </button>
              )}
            </div>

            <div className="allBTNSUMit-products">
              <button
                type="submit"
                id="contact-submitCancel"
                className="btn btn-secondary"
                onClick={navigateCorporatePage}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      )}

      {succeesMessage && (
        <form id="contact">
          <h3 className="alertthank">
            {' '}
            Thank You For Subscribing To Vitel Business Solutions
            <p>We Will Get Back To You Soon!</p>
          </h3>
          <hr />
          <h3>Submitted Details:</h3>
          <div className="tecShowDEtails">
            <div className="holdMain">Full Name:</div>
            <div className="yourDataN"> {sendData.fullName} </div>
          </div>

          <div className="tecShowDEtails">
            <div className="holdMain">phone number:</div>
            <div className="yourDataN"> {sendData.phoneNumber} </div>
          </div>
          <div className="tecShowDEtails">
            <div className="holdMain">Email:</div>
            <div className="yourDataN"> {sendData.emailAddress} </div>
          </div>
          <div className="tecShowDEtails">
            <div className="holdMain">Company Name:</div>
            <div className="yourDataN"> {sendData.companyName} </div>
          </div>

          <div className="tecShowDEtails">
            <div className="holdMain">RC Number:</div>
            <div className="yourDataN"> {sendData.rcNumber} </div>
          </div>
          <div className="tecShowDEtails">
            <div className="holdMain">Sim cards:</div>
            <div className="yourDataN"> {sendData.howManySim} </div>
          </div>
          <div className="tecShowDEtails">
            <div className="holdMain"> Data Per Sim card:</div>
            <div className="yourDataN"> {sendData.dataPerSim} </div>
          </div>
          <div className="tecShowDEtails">
            <div className="holdMain"> Talk Time Per Sim card:</div>
            <div className="yourDataN"> {sendData.talkTimePerSim} </div>
          </div>
          <div className="tecShowDEtails">
            <div className="holdMain"> Sms Per Sim card:</div>
            <div className="yourDataN"> {sendData.smsPerSim} </div>
          </div>
          <div className="tecShowDEtails">
            <div className="holdMain"> Duration :</div>
            <div className="yourDataN"> {sendData.duration} </div>
          </div>
          <div className="closeSHOWDetDiv">
            <button className="closeSHOWDet " onClick={navigateCorporatePage}>
              Close
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default CorporateProductForm;
