import React, { useEffect, useState } from 'react';
import './MyWishList.css';
import { Link, useNavigate } from 'react-router-dom';
import { orderLocationId, orderMenuId, vitelWirelessAxios } from '../../../../utility/axios';
import { MdFavorite } from 'react-icons/md';
import { addToCartProduct } from '../../../../redux/productCounter';
import { useDispatch, useSelector } from 'react-redux';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { MdDeleteForever } from 'react-icons/md';
import { Loader } from '../../../../utility/Loader';
import noproductimg from '../../../../images/noproduct.png';

const MyWishList = () => {
  const [favouritItemArr, setFavouritItemArr] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [showAlert, setShowAlert] = useState('');
  const [showAdded, setShowAdded] = useState(false);
  const [showLoader, setShowLaoder] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscribersData = JSON.parse(localStorage.getItem('subscriberInformation'));
  useEffect(() => {
    const fetchWishList = async () => {
      setShowLaoder(true);
      const getAllProduct = await vitelWirelessAxios.get(
        `/products/menus/${orderMenuId}?locationId=${orderLocationId}?sourceType=web`
      );
      const getSubscriberWishList = await vitelWirelessAxios.get(
        `subscribers/getProductWishlists/${subscribersData?.customerId}?sourceType=web`
      );

      console.log('getSubscriberWishList', getSubscriberWishList);

      const wishListProductIds = getSubscriberWishList.data.map(wishlistItem => wishlistItem.productId);
      const subCategoryImages = getAllProduct.data.products
        .filter(product => product.productDetailImages && product.productDetailImages.length > 0)
        .flatMap(product => product.productDetailImages);

      const filteredSubCategoryImages = subCategoryImages.filter(image => wishListProductIds.includes(image.productId));

      const filteredProducts = getAllProduct.data.products.filter(product =>
        product.productDetailImages.some(detailImage => wishListProductIds.includes(detailImage.productId))
      );

      setFavouritItemArr(filteredProducts);
      setShowLaoder(false);
      // console.log('subonfilterImages', filteredProducts);
    };

    fetchWishList();
  }, [subscribersData.customerId, refreshTrigger]);

  const handleAddToCart = pro => {
    dispatch(addToCartProduct(pro));
    setShowAlert(pro.productName);
    setShowAdded(true);
    // setItem(pro);
    // scrolltop();
    // setTimeout(() => {
    //   setShowAdded(false);
    // }, 3000);
  };

  const handleNavigateDetails = pro => {
    const encodedProductName = encodeURIComponent(pro.productName);
    navigate(`/product-details/id=${pro.productId}/name=${encodedProductName}`, {
      state: { product: pro },
    });
  };

  const handleRemoveFavourit = async (favItemDetails, newItem) => {
    const userInfor = {
      productId: favItemDetails.productDetailImages[0].productId,
      customerId: subscribersData.customerId,
      type: newItem,
      sourceType: 'web',
    };

    await vitelWirelessAxios.post('subscribers/createProductWishlist', userInfor);

    setRefreshTrigger(prev => !prev);
  };

  const handleProceedDelivery = () => {
    navigate('/delivery-pickup');
  };

  const goToAddFav = () => {
    navigate('/e-commerce');
  };

  return (
    <div className="parentWishList">
      <h4>
        My Wish List{' '}
        {showAdded && (
          <>
            <span className="successAlert">
              <IoCheckmarkCircle className="addedcheckIcon" />
              {showAlert} has been added to your cart!
            </span>
            <Link to="/shopping-cart" className="wishCheckout">
              Checkout Now
            </Link>
          </>
        )}
      </h4>
      <div className="wishlistWrapper">
        {showLoader ? (
          <Loader />
        ) : (
          <>
            {favouritItemArr && favouritItemArr.length === 0 ? (
              <div className="singleCardWish">
                <div className="wishListImage">
                  <img src={noproductimg} alt="" />
                </div>
                <div className="whislistInfo">
                  <h3>Add item to your wish</h3>
                  <button className="AddItem" onClick={goToAddFav}>
                    Add item
                  </button>
                </div>
              </div>
            ) : (
              favouritItemArr?.map((wishList, index) => {
                // Check if the currentQuantity in fieldItems is 0
                const isOutOfStock = wishList?.fields?.some(field =>
                  field.fieldItems?.some(item => item.currentQuantity === 0)
                );

                return (
                  <div className="singleCardWish" key={index}>
                    <div className="wishListImage">
                      <img src={wishList?.productDetailImages[0]?.displayImage} alt="" />
                    </div>

                    <div className="whislistInfo">
                      <MdDeleteForever
                        className="favoritIcon"
                        onClick={() => handleRemoveFavourit(wishList, 'exist')}
                      />
                      <h3>{wishList?.productDetailImages[0]?.productName.slice(0, 19) + '...'}</h3>
                      <p className="wishprice">
                        ₦ {parseInt(wishList?.productDetailImages[0]?.pricePerItem).toLocaleString()}
                      </p>
                      <p>{wishList?.productDetailImages[0]?.shortDescription.slice(0, 50) + '...'}</p>
                    </div>
                    <div className="overLayWish">
                      {isOutOfStock ? (
                        <button className="outofStock" disabled>
                          out of stock
                        </button>
                      ) : (
                        <button
                          className="addWishListToCart"
                          onClick={() => handleAddToCart(wishList?.productDetailImages[0])}
                        >
                          Add to Cart
                        </button>
                      )}

                      <button
                        className="viewWishListToCart"
                        onClick={() => handleNavigateDetails(wishList?.productDetailImages[0])}
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                );
              })
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MyWishList;
