import React, { useEffect, useState } from 'react';
import './TransactionTable.css';
import { vitelWirelessAxios } from '../../../../utility/axios';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import TopupHistoryTable from './TopupHistoryTable';
import PhysicalProdHisTable from './PhysicalProdHisTable';
import SubtransactionHistory from './SubtransactionHistory';

const TransactionTable = () => {
  const [subscriberHistory, setSubscriberHistory] = useState([]);
  const [subTransactionHistory, setSubTransactionHistory] = useState();
  const [showTopUpHistory, setShowTopHistory] = useState(false);
  const [topUpHistory, setTopUpHistory] = useState();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const subscribersData = JSON.parse(localStorage.getItem('subscriberInformation'));
  const navigation = useNavigate();

  useEffect(() => {
    // Set default start date to today
    const today = new Date().toISOString().split('T')[0];
    setStartDate(today);

    // Set default end date to any date in the next month
    const nextMonthDate = new Date();
    nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
    const formattedNextMonthDate = nextMonthDate.toISOString().split('T')[0];
    setEndDate(formattedNextMonthDate);
  }, []);

  useEffect(() => {
    const getSubscriberHistory = async () => {
      const mainSubscriberHistory = await vitelWirelessAxios.get(
        `/subscribers/getSubscriberOrders/${subscribersData.customerId}?sourceType=web`
      );
      setSubscriberHistory(mainSubscriberHistory.data);
    };
    getSubscriberHistory();
  }, [subscribersData.customerId]);

  const handleGetSubtransaction = async orderId => {
    const mainSubscriberHistory = await vitelWirelessAxios.get(
      `/subscribers/getOrdersReceipt/${orderId}?sourceType=web`
    );
    console.log('mainSubscriberHistory.data', mainSubscriberHistory.data);
    setSubTransactionHistory(mainSubscriberHistory.data);
  };

  const calculateVAT = (pricePerItem, quantity, addVat) => {
    if (addVat === 'Yes') {
      return (pricePerItem * quantity * 7.5) / 100;
    }
    return 0;
  };

  const vatCalculateGrandTotal = () => {
    return subTransactionHistory?.products?.reduce((total, item) => {
      return total + calculateVAT(item.pricePerItem, item.quantity, item.addVat);
    }, 0);
  };

  const calculateItemTotalPrice = (pricePerItem, quantity) => {
    return pricePerItem * quantity;
  };

  const calculateItemTotal = (pricePerItem, quantity, addVat) => {
    return calculateItemTotalPrice(pricePerItem, quantity) + calculateVAT(pricePerItem, quantity, addVat);
  };

  const calculateGrandTotal = () => {
    return subTransactionHistory?.products?.reduce((grandTotal, item) => {
      return grandTotal + calculateItemTotal(item.pricePerItem, item.quantity, item.addVat);
    }, 0);
  };

  const handleSelectChange = e => {
    const selectedValue = e.target.value;
    if (selectedValue === 'topUp') {
      handleShowHistory();
    } else {
      setShowTopHistory(false);
    }
  };

  const handleShowHistory = async () => {
    const hasMultipleEntries = subscribersData.phoneDetails.length > 1;

    let MSISDN;

    if (hasMultipleEntries) {
      const filtered = subscribersData.phoneDetails.filter(msisdn => msisdn.MSISDN === '2347120001199');

      MSISDN = filtered.length > 0 ? filtered[0].MSISDN : subscribersData.phoneDetails[0].MSISDN;
    } else {
      MSISDN = subscribersData.phoneDetails[0].MSISDN;
    }

    const correctMSISDN = {
      MSISDN: MSISDN,
      sourceType: 'web',
    };

    try {
      const subscriberTopUpHistory = await vitelWirelessAxios.post(`/generals/getTotUpHistory`, correctMSISDN);
      setTopUpHistory(subscriberTopUpHistory.data);
    } catch (error) {
      console.error('Error fetching top-up history:', error);
    }

    setShowTopHistory(true);
  };

  // const historyDateFilter = async endDatepassed => {
  //   const mainSubscriberHistory = await vitelWirelessAxios.get(
  //     `/subscribers/getSubscriberOrders/${subscribersData.customerId}`
  //   );

  //   const dataFilteredHistory = mainSubscriberHistory.data.filter(filterObj => {
  //     const createdDate = moment(filterObj.createdDateTime).format('YYYY-MM-DD');

  //     return createdDate === startDate || createdDate === endDatepassed;
  //   });

  //   setSubscriberHistory(dataFilteredHistory);
  // };

  const handlePeriodChange = async e => {
    const period = e.target.value;
    const endDate = moment(); // Current date
    let startDate;

    // Calculate the start date based on the selected period
    switch (period) {
      case 'oneMonth':
        startDate = moment().subtract(1, 'months');
        console.log('one months', startDate);
        break;
      case 'twoMonth':
        startDate = moment().subtract(2, 'months');
        break;
      case 'sixMonth':
        startDate = moment().subtract(6, 'months');
        break;
      default:
        startDate = null; // If no period is selected, don't filter
    }

    const mainSubscriberHistory = await vitelWirelessAxios.get(
      `/subscribers/getSubscriberOrders/${subscribersData.customerId}?sourceType=web`
    );

    const dataFilteredHistory = mainSubscriberHistory.data.filter(filterObj => {
      const createdDate = moment(filterObj.createdDateTime);
      if (!startDate) return true;

      return createdDate.isSameOrAfter(startDate) && createdDate.isSameOrBefore(endDate);
    });

    setSubscriberHistory(dataFilteredHistory);
  };

  return (
    <div className="parentTransactionDiv">
      <div className="TransactionDivTable">
        <div className="transactionHeader">
          <div className="componentheader">
            <span className="pageTitle">My Transactions</span>{' '}
            <div className="usageOptionDiv">
              <label htmlFor="" className="usageLabel">
                Filter By
              </label>
              <select onChange={e => handleSelectChange(e)}>
                <option value="">Select an option</option>
                <option value="PhysicalProducts">Physical Products</option>
                <option value="Data">Data Purchase</option>
                <option value="CreditVoucher">Credit Voucher</option>
                <option value="topUp">Airtime Top-Up</option>
                <option value="Device">Vitel Device</option>
                <option value="VAS">Value Added Services</option>
              </select>
            </div>
          </div>

          <div className="historyOptionDiv">
            <div className="usageOptionDiv">
              <label htmlFor="" className="usageLabel">
                Transaction period
              </label>
              <select onChange={e => handlePeriodChange(e)}>
                <option value="">Select transaction period</option>
                <option value="oneMonth">past one month</option>
                <option value="twoMonth">past two months</option>
                <option value="sixMonth">past six months</option>
              </select>
            </div>
            {/* <div className="historyDateDiv">
              <label htmlFor="startDate" className="usageLabel">
                Select start date
              </label>
              <input
                type="date"
                id="startDate"
                className="historyDatepick2"
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
              />
            </div>

            <div className="historyDateDiv">
              <label htmlFor="endDate" className="usageLabel">
                Select end date
              </label>
              <input
                type="date"
                id="endDate"
                className="historyDatepick2"
                value={endDate}
                min={startDate}
                onChange={e => {
                  historyDateFilter(e.target.value);
                  setEndDate(e.target.value);
                }}
              />
            </div> */}
          </div>
        </div>

        <div className="transactionListDiv">
          {showTopUpHistory ? (
            <TopupHistoryTable topUpHistory={topUpHistory} />
          ) : (
            <PhysicalProdHisTable
              subscriberHistory={subscriberHistory}
              handleGetSubtransaction={handleGetSubtransaction}
            />
          )}
        </div>
      </div>

      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">
                Purchase Details
              </h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <SubtransactionHistory
                subTransactionHistory={subTransactionHistory}
                vatCalculateGrandTotal={vatCalculateGrandTotal}
                calculateGrandTotal={calculateGrandTotal}
                calculateItemTotal={calculateItemTotal}
                calculateVAT={calculateVAT}
              />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionTable;
