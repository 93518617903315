import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import React from 'react';



const counterSlice = createSlice({
    name: 'cart',
    initialState: {
    items: [],
    totalAmount:0,
    totalCount: 0,
   
    
    
  },
reducers: {

  addToCart: (state, action) => {
    const itemInCart = state.items.find((item) => item.serializedId === action.payload.serializedId );
    if (itemInCart ) {
      itemInCart.quantity++;
    } else {
      state.items.push({ ...action.payload, quantity: 1 });
    }
  },

 

  markItemUnavailable:(state,action)=>{
    const item = state.items.find(item=> item.serializedId === action.payload.serializedId);
     if (item){
      item.isAvailable = true;
     }
  },
 
  getCartTotal: (state) => {
      let {totalAmount,totalCount} = state.items.reduce(
        
        (cartTotal,cartItem)=> {
        const {price,quantity} = cartItem;
        const  itemTotal = price * quantity;
        cartTotal.totalAmount += itemTotal;
        cartTotal.totalCount += quantity;
        return cartTotal;
         
      },
         {totalAmount: 0, totalCount: 0 }
      );
      state.totalAmount = parseInt(totalAmount.toFixed(2));
      state.totalCount = totalCount;
},

// Increase product quantity///////////////////////////////////////////
increase:(state,action) => {
        state.productItems  = state.productItems.map((item)=>{
          if(item.productId === action.payload ){
            return{...item, quantity:item.quantity + 1}
            }
               return item;
        });
},
remove:(state, action)=>{
        state.items = state.items.filter((item)=> item.serializedId !== action.payload);
    },

decrease:(state,action) => {
      state.items  = state.items.map((item)=>{
        if(item.comboId === action.payload && item.quantity >= 1){
          return{...item,quantity:item.quantity -= 1}
        }
          return item;
      })
    },

// clear product cart//////////////////////////////////////////////
     clearCart: (state) =>{
      state.items = [];
     },

    hasError:(state, action)=>{
      state.items = state.items.map((item)=>{
               item.fields.map((item)=>{
                 item.fieldItems.map((current)=>{
                    if(current.fieldItemId === action.payload && current.quantity < current.currentQuantity){
                              return {hasError:"Maximum"}
                    }
                 })
               })
      })
    },
}
})


export const { increase,getCartTotal,remove,decrease,clearCart, getCartItems,addToCart,hasError, markItemUnavailable } = counterSlice.actions;
export default counterSlice.reducer;



