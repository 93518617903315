import { CiInstagram } from 'react-icons/ci';
import './footer.css';
import { FaFacebookSquare, FaQuestionCircle, FaTiktok } from 'react-icons/fa';
import { FaXTwitter, FaSquareInstagram } from 'react-icons/fa6';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const navigateBottomMenu = () => {
    navigate('/footer-menu');
  };
  const navigateHome = () => {
    navigate('/');
  };

  const handleDataPlanPage = () => {
    navigate('/data-plan');
  };

  const getCurrentYear = () => {
    return moment().year();
  };

  return (
    <>
      <div className="footerSection">
        <div className="waves">
          <div className="wave" id="wave1"></div>
          <div className="wave" id="wave2"></div>
          <div className="wave" id="wave3"></div>
          {/*<div className="wave" id="wave4"></div> */}
        </div>
        <ul className="socialIcon">
          <li>
            <a href="">
              <FaFacebookSquare />
            </a>
          </li>
          <li>
            <a href="">
              <FaTiktok />
            </a>
          </li>
          <li>
            <a href="">
              <FaXTwitter />
            </a>
          </li>
          <li>
            <a href="">
              <FaSquareInstagram />
            </a>
          </li>
        </ul>

        <ul className="menuItem">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
          <li>
            <Link to="/about-us">About</Link>
          </li>
          <li>
            <Link to="/our-services">Our Services</Link>
          </li>
          <li>
            <Link to="/become-vitel-partner">Partners</Link>
          </li>
        </ul>
        <p className="footerParagr">
          ©{getCurrentYear()} vitel wireless Limited | All rights Reserved |{' '}
          <Link to="/privacy-policy">
            <span style={{ cursor: 'pointer' }}> Privacy Policy</span>
          </Link>
          {/* <Link to="/terms-condition"><span style={{cursor:"pointer"}}>Terms and Condition</span> </Link> */}
        </p>
      </div>
    </>
  );
};

export default Footer;
